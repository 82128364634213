/* 翻訳（中国語）
------------------------------------------------ */
.cn {
  .headerLogo > a,
  .drawer-modal__logo,
  .langTitle__title,
  .secMain__title,
  .commitment .commitment__title,
  .commitment .commitmentBlock__title,
  .info__title,
  .philosophy__read,
  .inquery__btn,
  .footer .footerInfo .footerInfo__name,
  .languageSwitch {
    font-family: $fontCn_02_700;
  }

  .drawer-modal__item,
  .gnavList,
  .footer {
    font-family: $fontCn_02_400;
  }

  .itemBox__title,
  .ankerNav__item,
  .lineup .card .card__title,
  .lineup .card .cardList .cardList__title,
  .infoHaccp .infoHaccp__title,
  .infoHaccp .infoHaccp__btn,
  .sales .info .info__title,
  .philosophy .philosophy__title,
  .table tr th,
  .development .developmentScene .card .card__title {
    font-family: $fontCn_01_700;
  }

  .commitment {
    .commitmentBlock {
      &__title {
        font-size: 1.8rem;
      }
    }
  }
}
