/* footer
------------------------------------------------ */
.footer {
  background: #edecea url(../images/common/footer/bg_01_sp@2x.png) no-repeat center bottom;
  background-size: contain;
  padding: 50px 0 92px;

  @include media(sm) {
    background: #edecea url(../images/common/footer/bg_01_pc@2x.png) no-repeat center bottom;
    background-size: 768px;
    padding-bottom: 100px;
  }

  @include media(md) {
    background-size: res(1366px);
    margin-bottom: res(74px);
    padding: res(90px) 0 res(184px);

    &.-lawer {
      margin-bottom: 0;
    }
  }

  @include media(lg) {
    background-size: 1366px;
    margin-bottom: 74px;
    padding: 90px 0 184px;
  }

  &__container {
    padding: 0 10px;

    @include media(sm) {
      max-width: 600px;
      margin: 0 auto;
      padding: 0;
    }

    @include media(md) {
      display: flex;
      max-width: none;
    }
  }

  .footerLogo {
    max-width: 76px;
    width: 100%;
    margin: 0 auto;

    @include media(md) {
      max-width: none;
      width: res(92px);
      margin: 0;
      margin-right: res(28px);
    }

    @include media(lg) {
      width: 92px;
      margin-right: 28px;
    }

    &::before {
      padding-top: percentage(148 / 76);
    }
  }

  .footerNav {
    margin-top: 30px;

    @include media(md) {
      margin-top: 0;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: -25px;

      @include media(md) {
        justify-content: center;
        margin-top: 0;
      }
    }

    &__item {
      width: 50%;
      margin-top: 25px;

      @include media(sm) {
        width: 33.333%;
      }

      @include media(md) {
        width: auto;
        margin-top: 0;
        margin-right: res(28px);

        &:last-child {
          margin-right: 0;
        }
      }

      @include media(lg) {
        margin-right: 28px;
      }

      & > a {
        font-size: 1.5rem;

        @include media(sm) {
          font-size: 1.5rem;
        }

        @include media(md) {
          font-size: res(1.5rem);
        }

        @include media(lg) {
          font-size: 1.5rem;
        }
      }
    }

    &__txt {
      margin-top: 25px;
      font-size: 1.3rem;

      @include media(md) {
        margin-top: res(20px);
        font-size: res(1.3rem);
        text-align: left;
      }

      @include media(lg) {
        margin-top: 20px;
        font-size: 1.3rem;
      }
    }
  }

  .footerInfo {
    margin-top: 40px;

    @include media(sm) {
      margin-top: 50px;
    }

    @include media(md) {
      margin-top: res(50px);
    }

    @include media(lg) {
      margin-top: 50px;
    }

    &__name {
      font-size: 1.5rem;
      font-weight: 600;

      @include media(sm) {
        font-size: 1.6rem;
      }

      @include media(md) {
        font-size: res(1.6rem);
      }

      @include media(lg) {
        font-size: 1.6rem;
      }
    }

    &__txt {
      margin-top: 12px;
      font-size: 1.3rem;
      line-height: line(21, 13);

      @include media(md) {
        margin-top: res(12px);
        font-size: res(1.3rem);
      }

      @include media(lg) {
        margin-top: 12px;
        font-size: 1.3rem;
      }
    }
  }

  .footer__language {
    margin-top: 40px;

    @include media(sm) {
      margin-top: 50px;
    }

    @include media(md) {
      margin-top: 0;
      margin-left: auto;
    }
  }

  .copyright {
    margin-top: 50px;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;

    @include media(sm) {
      margin-top: 60px;
    }

    @include media(md) {
      margin-top: res(94px);
      text-align: right;
    }

    @include media(lg) {
      margin-top: 94px;
    }
  }
}
