/* sales
------------------------------------------------ */
.sales {
  padding: 70px 0;

  @include media(md) {
    padding: res(92px) 0 res(90px);
  }

  @include media(lg) {
    padding: 92px 0 90px;
  }

  &__list {
    margin-top: 30px;

    @include media(md) {
      margin-top: res(30px);
    }

    @include media(lg) {
      margin-top: 30px;
    }
  }

  &__item {
    background-color: #f2efe1;
    margin-top: 15px;
    padding: 28px 20px 22px;
    border-radius: 1em;

    @include media(md) {
      margin-top: res(15px);
      padding: res(36px) res(40px) res(36px);
    }

    @include media(lg) {
      margin-top: 15px;
      padding: 36px 40px 36px;
    }

    &:first-child {
      margin-top: 0;
    }

    .info {
      @include media(md) {
        display: flex;
      }

      &__title {
        font-size: 2rem;
        font-weight: 600;

        @include media(md) {
          max-width: res(250px);
          width: 100%;
          font-size: res(2.6rem);
          line-height: line(34, 26);
        }

        @include media(lg) {
          max-width: 250px;
          font-size: 2.6rem;
        }
      }

      &__txt {
        margin-top: 14px;
        font-size: 1.6rem;
        line-height: line(30, 16);

        @include media(md) {
          margin-top: res(-4px);
          font-size: res(1.6rem);
        }

        @include media(lg) {
          margin-top: -4px;
          font-size: 1.6rem;
        }
      }
    }
  }
}
