/* 会社案内
------------------------------------------------ */
.company {
  .companyAbout {
    @include re-p;

    &__inner {
      padding: 70px 0;

      @include media(md) {
        padding: res(90px) 0;
      }

      @include media(lg) {
        padding: 90px 0;
      }
    }

    &__title {
      max-width: 74px;
      margin: 0 auto;

      @include media(md) {
        max-width: res(74px);
      }

      @include media(lg) {
        max-width: 74px;
      }
    }

    &__info {
      position: relative;
      margin-top: 26px;
      padding: 60px 0 62px;

      @include media(md) {
        margin-top: res(26px);
        padding: res(70px) 0 res(80px);
      }

      @include media(lg) {
        margin-top: 26px;
        padding: 70px 0 80px;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 23px;
        background-image: url(../images/common/bg/bg_04_gold.png);
        background-size: 64px;
        background-position: center;

        @include media(md) {
          height: res(23px);
          background-size: res(64px);
        }

        @include media(lg) {
          height: 23px;
          background-size: 64px;
        }
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    &__read {
      font-size: 2rem;
      font-weight: 600;
      letter-spacing: track(40);
      line-height: line(36, 20);
      text-align: center;

      @include media(md) {
        font-size: res(2.8rem);
      }

      @include media(lg) {
        font-size: 2.8rem;
      }
    }

    &__box {
      margin-top: 12px;

      @include media(md) {
        display: flex;
        max-width: res(980px);
        width: 100%;
        margin: res(32px) auto 0;
      }

      @include media(lg) {
        max-width: 980px;
        margin-top: 32px;
      }

      &:first-of-type {
        margin-top: 32px;

        @include media(md) {
          margin-top: res(54px);
        }

        @include media(lg) {
          margin-top: 54px;
        }
      }
    }

    &__txt {
      font-size: 1.6rem;
      line-height: line(30, 16);

      @include media(md) {
        max-width: res(510px);
        font-size: res(1.6rem);
      }

      @include media(lg) {
        max-width: 510px;
        font-size: 1.6rem;
      }
    }

    &__area {
      position: relative;

      @include media(md) {
        flex: 1;
      }
    }

    &__img {
      max-width: 184px;
      width: 100%;
      margin: 10px auto 0;

      @include media(md) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        max-width: res(247px);
        margin: 0;

        &.-illust01 {
          top: 0;
        }

        &.-illust02 {
          bottom: 0;
        }
      }

      @include media(lg) {
        max-width: 247px;
      }
    }
  }
}
