/* フォーム関連
------------------------------------------------- */
.form {
  // 共通箇所
  // --------------------------------------------
  input,
  textarea,
  select {
    padding: 8px;
    border: 1px solid #a09e9e;
    border-radius: 0.3em;
    font-size: 1.6rem;
    font-family: $font;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input[type="text"],
  select {
    width: 100%;
    height: 46px;
  }

  &__inner {
    margin-top: 50px;

    @include media(sm) {
      margin-top: res(70px);
    }

    @include media(lg) {
      margin-top: 70px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 36px;

    @include media(sm) {
      margin-top: res(44px);
    }

    @include media(md) {
      flex-direction: row;
    }

    @include media(lg) {
      margin-top: 44px;
    }

    &:first-child {
      margin-top: 0;
    }

    // input
    .input {
      position: relative;

      input {
        width: 100%;
        color: $color-bk !important;
      }
    }
  }

  &__title {
    @include media(md) {
      width: 100%;
      max-width: res(296px);
    }

    @include media(lg) {
      max-width: 296px;
    }

    & > .jp,
    & > .en {
      font-weight: 600;
    }

    & > .jp {
      font-size: 1.6rem;

      @include media(sm) {
        font-size: res(1.6rem);
      }

      @include media(lg) {
        font-size: 1.6rem;
      }
    }

    & > .en {
      margin-top: 10px;
      font-size: 1.5rem;

      @include media(sm) {
        margin-top: res(12px);
        font-size: res(1.5rem);
      }

      @include media(lg) {
        margin-top: 12px;
        font-size: 1.5rem;
      }
    }

    .red {
      color: $color-red;
    }
  }

  &__txt {
    margin-top: 14px;

    @include media(md) {
      margin-top: 0;
    }

    & > .jp,
    & > .en {
      font-weight: 400;
      line-height: line(28, 15);
    }

    & > .jp {
      font-size: 1.5rem;

      @include media(sm) {
        font-size: res(1.6rem);
      }

      @include media(lg) {
        font-size: 1.6rem;
      }
    }

    & > .en {
      margin-top: 6px;
      font-size: 1.5rem;

      @include media(sm) {
        margin-top: res(10px);
        font-size: res(1.5rem);
      }

      @include media(lg) {
        margin-top: 10px;
        font-size: 1.5rem;
      }
    }

    a {
      display: inline;
      text-decoration: underline;
      color: #1284cb;
    }
  }

  &__box {
    flex: 1;
    width: 100%;
    margin-top: 12px;

    @include media(md) {
      flex: 1;
      margin-top: 0;
    }

    // textarea
    .textarea {
      position: relative;

      textarea {
        width: 100%;
        line-height: line(22, 15);
      }
    }

    .formConsent {
      margin-top: 26px;
      position: relative;

      @include media(sm) {
        margin-top: res(36px);
      }

      @include media(lg) {
        margin-top: 36px;
      }

      & > label {
        display: flex;
      }

      input[type="checkbox"] {
        display: none;
      }

      &__btn {
        position: relative;
        background-image: url(../images/common/icon/check_off_01.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        width: 20px;
        height: 20px;
        margin-top: 6px;
        margin-right: 12px;
        border: solid 1px $color-bk;

        @include media(sm) {
          width: res(20px);
          height: res(20px);
          background-size: res(20px);
        }

        @include media(lg) {
          width: 20px;
          height: 20px;
          background-size: 20px;
        }
      }

      input[type="checkbox"]:checked ~ .formConsent__btn {
        background-image: url(../images/common/icon/check_on_01.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }


      &__txt {
        flex: 1;
        font-size: 1.8rem;
        line-height: line(28, 18);

        @include media(sm) {
          font-size: res(1.8rem);
        }

        @include media(lg) {
          font-size: 1.8rem;
        }
      }
    }

    .formConsent .mfp_err{
      position: absolute;
      bottom: -30px;
      left: 0;
    }
  }

  // エラー関係
  span.error {
    position: absolute;
    left: 0;
    bottom: -3px;
    transform: translateY(100%);
    font-size: 1rem;
    color: #e71f19;
  }

  /* フォームボタン
------------------------------------------------- */
  .formBtn {
    margin-top: 40px;

    @include media(sm) {
      margin-top: res(70px);
    }

    @include media(lg) {
      margin-top: 70px;
    }

    &__btn {
      position: relative;
      max-width: 400px;
      margin: 0 auto;

      @include media(sm) {
        max-width: res(400px);
      }

      @include media(lg) {
        max-width: 400px;
      }

      &:hover {
        opacity: 0.8;
      }

      &::after {
        content: "";
        position: absolute;
        top: 46%;
        transform: translateY(-50%);
        width: 28px;
        height: 6px;
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;

        @include media(sm) {
          width: res(28px);
          height: res(6px);
        }

        @include media(lg) {
          width: 28px;
          height: 6px;
        }
      }

      &.-nextBtn {
        &::after {
          right: 20px;
          background-image: url(../images/common/icon/arrow_wh_01.svg);

          @include media(sm) {
            right: res(20px);
          }

          @include media(lg) {
            right: 20px;
          }
        }
      }

      &.-backBtn {
        display: none;

        &::after {
          left: 20px;
          background-image: url(../images/common/icon/arrow_bk_01.svg);

          @include media(sm) {
            left: res(20px);
          }

          @include media(lg) {
            left: 20px;
          }
        }
      }

      .submitBtn {
        position: relative;
        display: block;
        width: 100%;
        padding: 18px 20px 22px;
        border: none;
        border-radius: 0.6em;
        font-size: 1.8rem;
        font-weight: 600;
        letter-spacing: track(70);
        text-align: left;
        color: $color-wh;
        cursor: pointer;

        @include media(sm) {
          padding: res(23px) res(20px) res(27px);
          font-size: res(1.8rem);
        }

        @include media(lg) {
          padding: 23px 20px 27px;
          font-size: 1.8rem;
        }

        &.-next {
          background-color: $color-main;
          border: 1px solid $color-main;
        }

        &.-back {
          background-color: $color-wh;
          border: 1px solid #453f39;
          color: $color-bk;
        }
      }
    }
  }
}
