/* 翻訳（英語）
------------------------------------------------ */
.en {
  .headerLogo > a,
  .drawer-modal__logo,
  .gnavList,
  .secMain__title,
  .commitment__title,
  .commitmentBlock__title,
  .info__title,
  .philosophy__read,
  .inquery__btn,
  .langTitle .langTitle__title {
    font-family: $fontEn_02;
  }

  .footer,
  .languageSwitch {
    font-family: $font;
  }
}
