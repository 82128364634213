/* お問い合わせ（入力・確認・完了）
------------------------------------------------- */
.contact {
  // 共通箇所
  // --------------------------------------------
  .contactHead {
    &.-thanks {
      @include media(sm) {
        text-align: center;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media(sm) {
        flex-direction: row;
      }
    }

    &__info {
      flex: 1;
    }

    &__title {
      margin-bottom: 34px;
      font-size: 2.4rem;

      @include media(sm) {
        margin-bottom: res(48px);
        font-size: res(4rem);
      }

      @include media(lg) {
        margin-bottom: 48px;
        font-size: 4rem;
      }
    }

    &__txt {
      &.-jp {
        font-size: 1.6rem;
        line-height: line(30, 16);

        @include media(sm) {
          font-size: res(1.6rem);
        }

        @include media(lg) {
          font-size: 1.6rem;
        }
      }

      &.-en {
        margin-top: 20px;
        font-size: 1.5rem;
        line-height: line(28, 15);

        @include media(sm) {
          margin-top: res(16px);
          font-size: res(1.5rem);
        }

        @include media(lg) {
          margin-top: 16px;
          font-size: 1.5rem;
        }
      }

      .red {
        color: $color-red;
      }
    }

    &__img {
      max-width: 280px;
      width: 100%;
      margin-top: 16px;

      @include media(sm) {
        margin-top: 0;
        margin-left: 40px;
      }

      @include media(md) {
        max-width: res(410px);
        margin-left: res(100px);
      }
      @include media(lg) {
        max-width: 410px;
        margin-left: 100px;
      }
    }
  }

  textarea[name="お問い合わせ内容"] {
    width: 100% !important;
    height: 100% !important;
  }

  // 確認画面
  // --------------------------------------------
  #mfp_phase_confirm {
    margin-top: 50px;

    @include media(sm) {
      margin-top: res(70px);
    }

    @include media(lg) {
      margin-top: 70px;
    }

    #mfp_confirm_table {
      margin-top: 30px;

      @include media(sm) {
        margin-top: res(70px);
      }

      @include media(lg) {
        margin-top: 70px;
      }

      .mfp_achroma,
      .mfp_colored {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 36px;
        font-size: 1.6rem;
        line-height: line(30, 16);
        text-align: left;

        @include media(sm) {
          margin-top: res(44px);
          font-size: res(1.6rem);
        }

        @include media(md) {
          flex-direction: row;
        }

        @include media(lg) {
          margin-top: 44px;
          font-size: 1.6rem;
        }

        th {
          @include media(md) {
            width: 100%;
            max-width: res(296px);
          }

          @include media(lg) {
            max-width: 296px;
          }

          & > .jp,
          & > .en {
            font-weight: 600;
          }

          & > .jp {
            font-size: 1.6rem;

            @include media(sm) {
              font-size: res(1.6rem);
            }

            @include media(lg) {
              font-size: 1.6rem;
            }
          }

          & > .en {
            margin-top: 10px;
            font-size: 1.5rem;

            @include media(sm) {
              margin-top: res(12px);
              font-size: res(1.5rem);
            }

            @include media(lg) {
              margin-top: 12px;
              font-size: 1.5rem;
            }
          }

          .red {
            color: $color-red;
          }
        }

        td {
          flex: 1;
          width: 100%;
          margin-top: 12px;
          line-height: 1.75;

          @include media(md) {
            flex: 1;
            margin-top: 0;
          }
        }
      }

      tbody tr:first-child {
        margin-top: 0;
        padding-top: 30px;
        border-top: 1px solid #242220;

        @include media(sm) {
          padding-top: 50px;
        }
      }

      tbody tr:last-child {
        padding-bottom: 40px;
        border-bottom: 1px solid #242220;

        @include media(sm) {
          padding-bottom: 50px;
        }
      }
    } // end .mfp_confirm_table

    // ボタン
    .mfp_buttons {
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
      margin-top: 40px;
      margin-left: -15px;

      @include media(sm) {
        margin-top: res(70px);
      }

      @include media(md) {
        max-width: res(680px);
        margin-right: auto;
        margin-left: auto;
      }

      @include media(lg) {
        max-width: 680px;
        margin-top: 70px;
      }

      .mfp_element_button {
        position: relative;
        max-width: none;
        margin: 0 0 0 15px;
        display: block;
        width: 100%;
        padding: 18px 20px 22px;
        border: none;
        border-radius: 0.6em;
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: track(70);
        text-align: left;
        color: $color-wh;
        cursor: pointer;

        @include media(sm) {
          width: calc(50% - #{res(40px)});
          margin-left: res(40px);
          padding: res(23px) res(20px) res(27px);
          font-size: res(1.8rem);
        }

        @include media(lg) {
          width: calc(50% - 40px);
          margin-left: 40px;
          padding: 23px 20px 27px;
          font-size: 1.8rem;
        }

        .submitBtn {
          text-align: center;
        }
      }

      .mfp_element_button#mfp_button_send {
        text-align: center;
        display: block;
        position: relative;
        background-color: $color-main;
        border: 1px solid $color-main;

        &::after {
          content: url(../images/common/icon/arrow_wh_01.svg);
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-74%);

          @include media(sm) {
            right: 20px;
          }
        }

        &:hover {
          opacity: 0.8;
        }
      }

      .mfp_element_button#mfp_button_cancel {
        text-align: center;
        display: block;
        position: relative;
        background-color: $color-wh;
        border: 1px solid #453f39;
        color: $color-bk;

        &::after {
          content: url(../images/common/icon/arrow_bk_01.svg);
          position: absolute;
          top: 50%;
          left: 8px;
          transform: translateY(-74%);

          @include media(sm) {
            left: 20px;
          }
        }

        &:hover {
          opacity: 0.8;
        }
      }
    } // end .mfp_buttons
  } // end #mfp_phase_confirm

  // 完了画面
  // --------------------------------------------
  .contactBack {
    max-width: 360px;
    margin: 38px auto 0;
    padding: 0 10px;

    @include media(sm) {
      max-width: res(320px);
      margin-top: res(60px);
      padding: 0;
    }

    @include media(lg) {
      max-width: 320px;
      margin-top: 50px;
    }
  }
}
