/* 採用情報
------------------------------------------------ */
.recruit {
  .secMain {
    padding-bottom: 0 !important;
  }

  .recruitHead {
    padding-bottom: 64px;
    text-align: center;

    @include media(md) {
      padding-bottom: res(86px);
    }

    @include media(lg) {
      padding-bottom: 86px;
    }

    &__title {
      font-size: 2rem;
      font-weight: 600;

      @include media(md) {
        font-size: res(2.6rem);
      }

      @include media(lg) {
        font-size: 2.6rem;
      }
    }

    &__txt {
      margin-top: 18px;
      font-size: 1.6rem;
      line-height: line(30, 16);

      @include media(md) {
        margin-top: res(34px);
        font-size: res(1.8rem);
      }

      @include media(lg) {
        margin-top: 34px;
        font-size: 1.8rem;
      }
    }
  }

  .recruitReq {
    @include re-p;
    background-color: $color-bg;
    padding-top: 70px;
    padding-bottom: 70px;

    @include media(md) {
      padding-top: res(90px);
      padding-bottom: res(90px);
    }

    @include media(lg) {
      padding-top: 90px;
      padding-bottom: 90px;
    }

    &__title {
      position: relative;
      margin-top: 34px;
      padding-left: 16px;
      font-size: 1.8rem;
      font-weight: 600;
      color: $color-main;

      @include media(md) {
        margin-top: res(54px);
        padding-left: res(20px);
        font-size: res(2.6rem);
      }

      @include media(lg) {
        margin-top: 54px;
        padding-left: 20px;
        font-size: 2.6rem;
      }

      &::before {
        content: "";
        position: absolute;
        top: 55%;
        left: 0;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        background-color: $color-main;
        border-radius: 10em;

        @include media(md) {
          width: res(10px);
          height: res(10px);
        }

        @include media(lg) {
          width: 10px;
          height: 10px;
        }
      }
    }

    &__table {
      margin-top: 22px;

      @include media(md) {
        margin-top: res(32px);
      }

      @include media(lg) {
        margin-top: 32px;
      }
    }
  }

  .recruitNote {
    margin-top: 40px;
    text-align: center;

    @include media(md) {
      margin-top: res(60px);
    }

    @include media(lg) {
      margin-top: 60px;
    }

    &__txt {
      font-size: 1.4rem;

      @include media(md) {
        font-size: res(1.8rem);
      }

      @include media(lg) {
        font-size: 1.8rem;
      }
    }

    &__tel {
      margin-top: 14px;
      font-size: 1.8rem;

      @include media(md) {
        margin-top: res(20px);
        font-size: res(2rem);
      }

      @include media(lg) {
        margin-top: 20px;
        font-size: 2rem;
      }
    }
  }
}
