/* プライバシーポリシー
------------------------------------------------ */
.privacy {
  .secMain {
    margin-top: 40px;
    padding-bottom: 70px;

    @include media(md) {
      margin-top: res(90px);
      padding-bottom: res(102px);
    }

    @include media(lg) {
      margin-top: 90px;
      padding-bottom: 102px;
    }
  }

  .privacyJp {
    padding-bottom: 65px;
    border-bottom: 1px solid #a09e9e;

    @include media(md) {
      padding-bottom: res(92px);
    }

    @include media(lg) {
      padding-bottom: 92px;
    }
  }

  .privacyEn {
    margin-top: 65px;

    @include media(md) {
      margin-top: res(85px);
    }

    @include media(lg) {
      margin-top: 85px;
    }
  }

  &__title {
    font-size: 2.4rem;
    font-weight: 600;
    text-align: center;

    @include media(sm) {
    }

    @include media(md) {
      font-size: res(4.2rem);
    }

    @include media(lg) {
      font-size: 4.2rem;
    }
  }

  &__txt {
    margin-top: 40px;
    font-size: 1.6rem;
    line-height: line(30, 16);

    @include media(sm) {
      margin-top: 55px;
    }

    @include media(md) {
      margin-top: res(90px);
      font-size: res(1.6rem);
    }

    @include media(lg) {
      margin-top: 90px;
      font-size: 1.6rem;
    }
  }

  .prList {
    &__item {
      margin-top: 32px;

      @include media(md) {
        margin-top: res(58px);

        &:first-child {
          margin-top: res(61px);
        }
      }

      @include media(lg) {
        margin-top: 58px;

        &:first-child {
          margin-top: 61px;
        }
      }
    }

    &__title {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: line(30, 18);
      padding-left: 1.1em;
      text-indent: -1.1em;

      @include media(md) {
        font-size: res(1.8rem);
        padding-left: 0em;
        text-indent: 0em;
      }

      @include media(lg) {
        font-size: 1.8rem;
      }

      &.-en {
        padding-left: 1.2em;
        text-indent: -1.2em;

        @include media(md) {
          padding-left: 0em;
          text-indent: 0em;
        }
      }

      & > .azuki {
        color: $color-main;
        margin-right: 0.1em;

        @include media(md) {
          margin-right: 0;
        }

        &.-en {
          margin-right: 0.2em;

          @include media(md) {
            margin-right: 0.1em;
          }
        }
      }
    }

    &__txt {
      margin-top: 10px;
      font-size: 1.6rem;
      line-height: line(30, 16);

      & > .bold {
        font-weight: 600;
      }

      @include media(md) {
        margin-top: res(12px);
        font-size: res(1.6rem);
      }

      @include media(lg) {
        margin-top: 12px;
        font-size: 1.6rem;
      }
    }

    .mtop01 {
      margin-top: 22px;

      @include media(md) {
        margin-top: res(20px);
      }

      @include media(lg) {
        margin-top: 20px;
      }

      &.-en {
        margin-top: 8px;

        @include media(md) {
          margin-top: res(20px);
        }

        @include media(lg) {
          margin-top: 20px;
        }
      }
    }

    .mtop02 {
      margin-top: 0;

      @include media(md) {
        margin-top: res(25px);
      }

      @include media(lg) {
        margin-top: 25px;
      }

      &.-en {
        margin-top: 8px;

        @include media(md) {
          margin-top: res(25px);
        }

        @include media(lg) {
          margin-top: 25px;
        }
      }
    }
  }

  .points {
    &__item {
      font-size: 1.6rem;
      line-height: line(30, 16);
      padding-left: 1em;
      text-indent: -1em;

      @include media(md) {
        font-size: res(1.6rem);
      }

      @include media(lg) {
        font-size: 1.6rem;
      }
    }
  }

  .narrow {
    letter-spacing: track(-5);

    @include media(sm) {
      letter-spacing: track(50);
    }
  }
}
