/* スライドショー
-------------------------------------------- */
.mv {
  position: relative;
  width: 100%;
  margin-top: 62px;

  @include media(md) {
    height: calc(100vh - #{res(74px)});
    margin-top: 0;
    padding-top: 0;
  }

  @include media(lg) {
    height: calc(100vh - 74px);
  }

  .mvInfo {
    display: flex;
    padding: 32px 20px;

    @include media(sm) {
      padding: 32px 40px;
    }

    @include media(md) {
      position: absolute;
      top: 8%;
      left: 3.4%;
      z-index: 100;
      flex-direction: column;
      align-items: center;
      padding: 0;
    }

    &__logo {
      width: 76px;
      margin-right: 10px;

      @include media(sm) {
        width: 90px;
        margin-right: 12px;
      }

      @include media(md) {
        width: res(110px);
        margin-right: 0;
      }

      @include media(lg) {
        width: 110px;
      }

      &::before {
        padding-top: percentage(148 / 76);
      }
    }

    &__txt {
      max-width: 56.71px;

      @include media(sm) {
        max-width: 70px;
      }

      @include media(md) {
        max-width: res(82px);
        margin-top: res(38px);
      }

      @include media(lg) {
        max-width: 82px;
        margin-top: 38px;
      }
    }
  }

  .swiperMv {
    width: 100%;

    .slide-img {
      height: 340px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @include media(sm) {
        height: 600px;
      }

      @include media(md) {
        height: calc(100vh - #{res(74px)});
      }

      @include media(lg) {
        height: calc(100vh - 74px);
      }

      &.-slide01 {
        background-image: url(../images/mv/mv_01_sp@2x.png);

        @include media(md) {
          background-image: url(../images/mv/mv_01_pc@2x.png);
        }
      }

      &.-slide02 {
        background-image: url(../images/mv/mv_02_sp@2x.png);

        @include media(md) {
          background-image: url(../images/mv/mv_02_pc@2x.png);
        }
      }

      &.-slide03 {
        background-image: url(../images/mv/mv_03_sp@2x.png);

        @include media(sm) {
          background-image: url(../images/mv/mv_03_pc@2x.png);
        }
      }

      &.-slide04 {
        background-image: url(../images/mv/mv_04_sp@2x.png);

        @include media(sm) {
          background-image: url(../images/mv/mv_04_pc@2x.png);
        }
      }

      &.-slide04_en {
        background-image: url(../images/mv/mv_04_sp@2x.png);

        @include media(sm) {
          background-image: url(../images/mv/mv_04_en_pc@2x.png);
        }
      }

      &.-slide04_cn {
        background-image: url(../images/mv/mv_04_sp@2x.png);

        @include media(sm) {
          background-image: url(../images/mv/mv_04_cn_pc@2x.png);
        }
      }
    }
  }
}

/* スマホ横
------------------------------------------------ */
@media only screen and (orientation: landscape) and (max-width: 813px) {
}
