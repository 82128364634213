/* ankerNav
------------------------------------------------ */
.ankerNav {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    width: 50%;
    height: 88px;
    padding: 10px 0;
    border-bottom: 1px solid $color-main;

    @include media(md) {
      width: 20%;
      height: res(108px);
      padding: 0 0 res(14px);
    }

    @include media(lg) {
      height: 108px;
      padding: 0 0 14px;
    }

    &:last-child {
      width: 100%;

      @include media(md) {
        width: 20%;
      }
    }

    & > a {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding-bottom: 20px;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: line(24, 16);
      color: $color-main;
      text-align: center;

      @include media(md) {
        padding-bottom: res(30px);
        font-size: res(2rem);
      }

      @include media(lg) {
        padding-bottom: 30px;
        font-size: 2rem;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 100%;
        background-color: $color-main;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 16px;
        height: 16px;
        background-image: url(../images/common/icon/arrow_pu_02.svg);
        background-repeat: no-repeat;
        background-size: contain;

        @include media(md) {
          width: res(24px);
          height: res(24px);
        }

        @include media(lg) {
          width: 24px;
          height: 24px;
        }
      }
    }

    &:first-child {
      & > a::before {
        @include media(md) {
          display: none !important;
        }
      }
    }

    &:nth-child(odd) {
      & > a::before {
        display: none;

        @include media(md) {
          display: block;
        }
      }
    }

    &:last-child {
      & > a::before {
        display: none;

        @include media(md) {
          display: block;
        }
      }
    }
  }
}
