/* sec
------------------------------------------------ */
.sec {
  @include re-p;
  position: relative;
  overflow: hidden;

  .secMv {
    position: relative;
    padding: 0 10px;

    @include media(sm) {
      padding: 0 30px;
    }

    @include media(md) {
      padding: 0;
    }

    &__container {
      display: flex;
    }

    &__title {
      max-width: 74px;
      width: 100%;
      margin-top: 20px;

      @include media(sm) {
        max-width: 124px;
        margin-top: 30px;
        padding-left: 20px;
      }

      @include media(md) {
        max-width: res(110px);
        margin-top: res(40px);
        padding-left: 0;
      }

      @include media(lg) {
        max-width: 110px;
        margin-top: 40px;
      }

      & > .txt {
        position: relative;
        padding-top: 16px;
        font-size: 2.4rem;
        letter-spacing: track(140);
        line-height: 1.2;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;

        @include media(sm) {
          padding-top: 16px;
          font-size: 2.8rem;
        }

        @include media(md) {
          padding-top: res(24px);
          font-size: res(3.8rem);
        }

        @include media(lg) {
          padding-top: 28px;
          font-size: 3.8rem;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 53%;
          transform: translateX(-50%);
          height: 3px;
          background-color: $color-main;

          @include media(md) {
            height: res(3px);
          }

          @include media(lg) {
            height: 3px;
          }
        }
      }

      // タイトルが1行の場合
      &.-row01 {
        padding-left: 18px;

        @include media(sm) {
          padding-left: 20px;
        }

        .txt {
          &::before {
            width: 25px;

            @include media(sm) {
              width: 34px;
            }

            @include media(md) {
              width: res(37px);
            }

            @include media(lg) {
              width: 37px;
            }
          }
        }
      }

      // タイトルが2行の場合
      &.-row02 {
        padding-left: 0 !important;

        .txt {
          @include media(sm) {
            margin-left: -8px;
          }

          @include media(md) {
            margin-left: res(-10px);
          }

          @include media(lg) {
            margin-left: -10px;
          }

          &::before {
            width: 54px;

            @include media(sm) {
              width: 64px;
            }

            @include media(md) {
              width: res(80px);
            }

            @include media(lg) {
              width: 80px;
            }
          }
        }
      }
    }

    &__area {
      position: relative;
      flex: 1;
      height: 190px;

      @include media(sm) {
        height: 222px;
      }

      @include media(md) {
        height: res(340px);
      }

      @include media(lg) {
        height: 340px;
      }
    }

    &__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 356px;
      border-radius: 0 0 0 1.6em;
      overflow: hidden;

      @include media(sm) {
        width: 878px;
      }

      @include media(md) {
        width: res(1344px);
      }

      @include media(lg) {
        width: 1344px;
      }

      &::before {
        padding-top: percentage(190 / 356);

        @include media(sm) {
          padding-top: percentage(340 / 1344);
        }
      }
    }
  }

  .secMain {
    margin-top: 64px;
    padding: 0 10px 90px;

    @include media(sm) {
      padding: 0 30px 90px;
    }

    @include media(md) {
      margin-top: res(110px);
      padding: 0 0 res(115px);
    }

    @include media(lg) {
      margin-top: 110px;
      padding: 0 0 115px;
    }

    &__title {
      position: relative;
      padding-left: 24px;
      font-size: 2.4rem;
      font-weight: 600;
      line-height: line(34, 24);

      @include media(md) {
        padding-left: res(30px);
        font-size: res(3.4rem);
      }

      @include media(lg) {
        padding-left: 30px;
        font-size: 3.4rem;
      }

      &.-small {
        font-size: 2.2rem;
        line-height: line(32, 22);
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        display: block;
        width: 6px;
        height: 50%;

        @include media(md) {
          width: res(6px);
        }

        @include media(lg) {
          width: 6px;
        }
      }

      &::before {
        top: 50%;
        transform: translateY(-99%);
        background-color: #c7a265;
      }

      &::after {
        top: 50%;
        transform: translateY(-1%);
        background-color: $color-main;
      }

      &.-wh {
        &::after {
          background-color: $color-wh;
        }
      }
    }
  }
}
