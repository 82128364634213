/* development
------------------------------------------------ */
.development {
  @include re-p;
  padding-top: 70px;
  padding-bottom: 68px;
  background-color: $color-main;
  color: $color-wh;

  @include media(md) {
    padding-top: res(88px);
    padding-bottom: res(86px);
  }

  @include media(lg) {
    padding-top: 88px;
    padding-bottom: 86px;
  }

  .developmentAbout {
    @include re-p;
    background-image: url(../images/pages/products/development_bg_01_sp.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 30px;
    padding-top: 40px;
    padding-bottom: 48px;

    @include media(md) {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-image: url(../images/pages/products/development_bg_01_pc.png);
      margin-top: res(50px);
      padding-top: res(64px);
      padding-bottom: res(84px);
    }

    @include media(lg) {
      margin-top: 50px;
      padding-top: 64px;
      padding-bottom: 84px;
    }

    &__img {
      max-width: 95px;
      width: 100%;
      margin: 0 auto;

      @include media(md) {
        max-width: res(125px);
      }

      @include media(lg) {
        max-width: 125px;
      }
    }

    &__txt {
      margin-top: 20px;
      padding: 0 14px 4px;
      border-right: 1px solid $color-wh;
      border-left: 1px solid $color-wh;
      font-size: 1.6rem;
      line-height: line(30, 16);

      @include media(md) {
        margin-top: res(26px);
        padding: 0 res(32px) res(4px);
        font-size: res(1.8rem);
        text-align: center;
      }

      @include media(lg) {
        margin-top: 26px;
        padding: 0 32px 4px;
        font-size: 1.8rem;
      }
    }
  }

  .developmentScene {
    margin-top: 46px;

    @include media(md) {
      margin-top: res(50px);
    }

    @include media(lg) {
      margin-top: 50px;
    }

    &__read {
      font-size: 1.8rem;

      @include media(md) {
        font-size: res(2rem);
      }

      @include media(lg) {
        font-size: 2rem;
      }
    }

    &__list {
      margin-top: 18px;

      @include media(md) {
        display: flex;
        flex-wrap: wrap;
        margin-top: res(20px);
        margin-left: res(-36px);
      }

      @include media(lg) {
        margin-top: 20px;
      }
    }

    &__item {
      background-color: $color-wh;
      margin-top: 12px;
      padding: 20px 14px;
      border-radius: 1em;

      @include media(md) {
        width: calc(33.333% - #{res(36px)});
        margin-top: 0;
        margin-left: res(36px);
        border-radius: 1.2em;

        &:nth-child(n + 4) {
          margin-top: 32px;
        }
      }

      @include media(lg) {
        width: calc(33.333% - 36px);
        margin-left: 36px;
        border-radius: 1.2em;

        &:nth-child(n + 4) {
          margin-top: 32px;
        }
      }

      &:first-child {
        margin-top: 0;
      }

      .card {
        display: flex;
        align-items: center;
        height: 100%;
        color: $color-bk;

        &__img {
          max-width: 60px;
          width: 100%;
          margin-right: 16px;

          @include media(md) {
            max-width: res(78px);
            margin-right: res(14px);
          }

          @include media(lg) {
            max-width: 78px;
            margin-right: 14px;
          }
        }

        &__info {
        }

        &__title {
          font-size: 1.8rem;
          font-weight: 600;

          @include media(md) {
            font-size: res(2rem);
          }

          @include media(lg) {
            font-size: 2rem;
          }
        }

        &__txt {
          margin-top: 12px;
          font-size: 1.5rem;
          line-height: line(28, 15);

          @include media(md) {
            margin-top: res(16px);
            font-size: res(1.6rem);
          }

          @include media(lg) {
            margin-top: 16px;
            font-size: 1.6rem;
          }
        }
      }
    }

    &__txt,
    .developmentSceneNote .developmentSceneNote__txt {
      font-size: 1.6rem;
      line-height: line(30, 16);

      @include media(md) {
        font-size: res(1.6rem);
      }

      @include media(lg) {
        font-size: 1.6rem;
      }
    }

    &__txt {
      margin-top: 36px;

      @include media(md) {
        margin-top: res(44px);
      }

      @include media(lg) {
        margin-top: 44px;
      }
    }

    .developmentSceneNote {
      margin-top: 30px;

      @include media(md) {
        margin-top: res(36px);
      }

      @include media(lg) {
        margin-top: 36px;
      }

      &__txt {
        padding-left: 1em;
        text-indent: -1em;
      }
    }
  }
}
