//font-family
$font: "Shippori Mincho", serif;

// English
$fontEn_01: "Noto Sans", sans-serif;
$fontEn_02: "Libre Baskerville", serif;

// Chinese
$fontCn_01_400: "NotoSansSC-Regular", sans-serif;
$fontCn_01_700: "NotoSansSC-Bold", sans-serif;
$fontCn_02_400: "NotoSerifSC-Regular", serif;
$fontCn_02_700: "NotoSerifSC-Bold", serif;

//font-size
$font-size: 10px;

// カラー
$color-bk: #242220;
$color-wh: #fff;
$color-main: #903a5d;
$color-red: #d30000;
$color-bg: #f2efe1;
