/* btn関連
------------------------------------------------ */
.btn01,
.btn02,
.btn03 {
  width: 100%;

  & > a,
  & > div {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      transform: translateY(-50%);
      width: 28px;
      height: 6px;
      background-repeat: no-repeat;
      background-position: left;
      background-size: contain;

      @include media(sm) {
        width: res(28px);
        height: res(6px);
      }

      @include media(lg) {
        width: 28px;
        height: 6px;
      }
    }
  }
}

.btn01,
.btn03 {
  margin: 0 auto;

  & > a,
  & > div {
    background-color: $color-main;
    padding: 18px 20px 22px;
    border-radius: 0.6em;
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: track(70);
    color: $color-wh;

    @include media(sm) {
      padding: res(23px) res(20px) res(27px);
      font-size: res(1.8rem);
    }

    @include media(lg) {
      padding: 23px 20px 27px;
      font-size: 1.8rem;
    }

    &::after {
      top: 46%;
      right: 20px;
      background-image: url(../images/common/icon/arrow_wh_01.svg);

      @include media(sm) {
        right: res(20px);
      }

      @include media(lg) {
        right: 20px;
      }
    }
  }
}

.btn02 {
  & > a,
  & > div {
    padding-bottom: 10px;
    border-bottom: 3px solid $color-main;
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: track(70);
    color: $color-main;

    @include media(sm) {
      padding-bottom: res(10px);
      font-size: res(1.8rem);
    }

    @include media(lg) {
      padding-bottom: 10px;
      font-size: 1.8rem;
    }

    &::after {
      top: 32%;
      right: 10px;
      background-image: url(../images/common/icon/arrow_pu_01.svg);

      @include media(sm) {
        right: res(10px);
      }

      @include media(lg) {
        right: 10px;
      }
    }
  }
}

.btn03 {
  & > a,
  & > div {
    background-color: $color-wh;
    border: 1px solid $color-bk;
    color: $color-bk;
    text-align: center;

    &::after {
      right: auto;
      left: 20px;
      background-image: url(../images/common/icon/arrow_bk_01.svg);

      @include media(sm) {
        left: res(20px);
      }

      @include media(lg) {
        left: 20px;
      }
    }
  }
}

.btn01.--en a{
  font-family: $font;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: track(70);

  @include media(sm) {
    font-size: res(1.8rem);
  }

  @include media(lg) {
    font-size: 1.8rem;
  }
}
