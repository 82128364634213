/* 翻訳ページ共通
------------------------------------------------ */
.en,
.cn {
  .anker {
    @include media(md) {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
  }

  .header {
    .gnav__language {
      @include media(md) {
        margin-left: res(44px);
      }

      @include media(lg) {
        margin-left: 44px;
      }
    }
  }

  .mv {
    .mvInfo {
      width: 100%;
      align-items: flex-end;

      @include media(md) {
        display: block;
      }

      &__logo {
        @include media(md) {
          max-width: res(191px);
          width: 100%;

          &::before {
            padding-top: percentage(357 / 191);
          }
        }

        @include media(lg) {
          max-width: 191px;
        }
      }

      &__txt {
        margin-bottom: 6px;
        margin-left: auto;
        max-width: 156px;

        @include media(md) {
          display: none;
        }
      }
    }
  }
}

.lang {
  .secMain {
    margin-top: 0 !important;
  }

  &__about {
    .langAbout {
      @include re-p;
      padding-top: 70px;
      padding-bottom: 60px;

      @include media(md) {
        padding-top: res(76px);
        padding-bottom: res(71px);
      }

      @include media(lg) {
        padding-top: 76px;
        padding-bottom: 71px;
      }

      &__list {
        margin: 0 auto;
        margin-top: 48px;
        max-width: 660px;

        @include media(md) {
          margin-top: res(72px);
          max-width: 100%;
        }

        @include media(lg) {
          margin-top: 72px;
        }
      }

      .itemBox {
        margin-top: 40px;

        @include media(md) {
          display: flex;
          margin-top: res(60px);
        }

        @include media(lg) {
          margin-top: 60px;
        }

        &:first-child {
          margin-top: 0;
        }

        &__img {
          margin: 0 auto;
          width: 260px;

          @include media(md) {
            margin: 0;
            width: res(390px);
            flex-shrink: 0;
          }

          @include media(lg) {
            width: 390px;
          }

          & > img {
            width: 100%;
            height: auto;
          }
        }

        &__container {
          margin-top: 20px;

          @include media(md) {
            margin-top: 0;
            margin-left: res(90px);
          }

          @include media(lg) {
            margin-left: 90px;
          }

          &.-first {
            margin-top: 30px;

            @include media(sm) {
            }

            @include media(md) {
              margin-top: 0;
            }
          }
        }

        &__txt {
          margin-top: 16px;
          font-size: 1.6rem;
          line-height: line(32, 16);

          @include media(md) {
            font-size: res(1.6rem);
          }

          @include media(lg) {
            font-size: 1.6rem;
          }
        }

        &__title {
          font-size: 1.9rem;
          font-weight: 600;
          line-height: line(32, 20);

          @include media(md) {
            font-size: res(2.6rem);
          }

          @include media(lg) {
            font-size: 2.6rem;
          }

          &.-first {
            margin-top: 30px;

            @include media(md) {
              margin-top: res(40px);
            }

            @include media(lg) {
              margin-top: 40px;
            }
          }
        }
      }

      &__img {
        margin: 13px calc(50% - 50vw) 0;

        @include media(sm) {
          margin: 13px auto;
          width: 100%;
          max-width: 660px;
        }

        @include media(md) {
          max-width: 100%;
          margin-top: res(21px);
        }

        @include media(lg) {
          margin-top: 21px;
        }

        & > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__anker {
    padding: 60px 0 70px;

    @include media(md) {
      padding: res(100px) 0;
    }

    @include media(lg) {
      padding: 100px 0;
    }

    .ankerNav {
      &__item {
        @include media(md) {
          width: 33.333%;
          padding: 0 0 res(14px);
        }

        @include media(lg) {
          padding: 0 0 14px;
        }

        &:last-child {
          width: 50%;

          @include media(md) {
            width: 33.333%;
          }
        }

        & > a {
          &.-small {
            font-size: 1.3rem;

            @include media(md) {
              font-size: res(2rem);
            }
            @include media(lg) {
              font-size: 2rem;
            }
          }
        }

        &:first-child,
        &:nth-child(4) {
          & > a::before {
            @include media(md) {
              display: none !important;
            }
          }
        }

        &:last-child {
          & > a::before {
            display: block;
          }
        }

        &:nth-child(n + 4) {
          @include media(md) {
            margin-top: res(30px);
          }

          @include media(lg) {
            margin-top: 30px;
          }
        }
      }
    }
  }

  &__lineup {
    .lineup {
      padding-top: 30px;

      @include media(md) {
        padding-top: res(70px);
      }

      @include media(lg) {
        padding-top: 70px;
      }

      .lineup__item {
        .card {
          &__title {
            &.-row02 {
              line-height: line(30, 20);

              @include media(md) {
                line-height: 1;
              }

              &::before {
                height: 48px;

                @include media(md) {
                  height: res(25px);
                }

                @include media(lg) {
                  height: 25px;
                }
              }
            }
          }
        }
        .cardList {
          flex-direction: column;

          @include media(md) {
            flex-direction: row;
          }

          &__title {
            max-width: none;

            @include media(md) {
              max-width: res(140px);
            }

            @include media(lg) {
              max-width: 140px;
            }
          }
        }
      }
    }
  }

  &__commitment {
    .commitment {
      padding-top: 70px;

      @include media(md) {
        padding-top: res(90px);
      }

      @include media(lg) {
        padding-top: 90px;
      }

      &__title {
        .txt {
          line-height: line(28, 22);
        }
      }

      .commitmentBlock {
        &__title {
          max-width: 120px;
          font-size: 1.4rem;
          line-height: line(21, 14);

          @include media(md) {
            max-width: res(170px);
            font-size: res(2.2rem);
          }

          @include media(lg) {
            max-width: 170px;
            font-size: 2.2rem;
          }
        }

        &__item {
          .info {
            &__title {
              &::before {
                top: 0.04em;

                @include media(md) {
                  top: 0.1em;
                }
              }

              &.-row02 {
                line-height: line(28, 20);

                &::before {
                  top: 0.2em;

                  @include media(md) {
                    top: 0.3em;
                  }
                }
              }
            }

            &__item {
              padding-left: 0.5em;
              text-indent: -0.5em;
            }

            .infoHaccp {
              &__title {
                line-height: line(30, 18);

                @include media(md) {
                  line-height: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  &__dev {
    .development {
      .developmentScene {
        &__item {
          .card {
            &__title {
              line-height: line(26, 18);
            }

            &__txt {
              margin-top: 2px;

              @include media(md) {
                margin-top: res(2px);
              }

              @include media(lg) {
                margin-top: 2px;
              }
            }
          }
        }

        .developmentSceneNote {
          &__txt {
            padding-left: 0.5em;
            text-indent: -0.5em;
          }
        }
      }
    }
  }

  &__sales {
    .sales {
      &__item {
        .info {
          &__title {
            line-height: line(28, 20);
          }
        }
      }
    }
  }

  &__philosophy {
    .philosophy {
      &::before {
        display: none;
      }

      &__inner {
        padding: 70px 0 74px;

        @include media(md) {
          padding: res(94px) 0 res(74px);
        }

        @include media(lg) {
          padding: 94px 0 74px;
        }
      }

      &__illust {
        position: absolute;
        top: -3%;
        right: 0;
        max-width: 88px;
        width: 100%;

        @include media(sm) {
          max-width: 120px;
        }

        @include media(md) {
          top: -8%;
          max-width: res(164px);
        }

        @include media(lg) {
          max-width: 164px;
        }
      }
    }
  }

  &__overview {
    padding-bottom: 90px;

    @include media(md) {
      padding-bottom: res(100px);
    }

    @include media(lg) {
      padding-bottom: 100px;
    }

    .overview {
      .table {
        tr {
          td {
            font-weight: 400 !important;
          }
        }
      }
    }
  }

  &__inquery {
    .inquery {
      &__btn {
        margin-top: 0;
      }
    }
  }
}

.cardBtn{
  margin-top: 17px;

  @include media(sm) {
    max-width: 500px;
    margin: 0 auto;
  margin-top: 17px;
  }

  @include media(md) {
    max-width: none;
    margin-top: res(27px);
  }

  @include media(lg) {
    margin-top: 27px;
  }
}
