/* わらび餅の食べかた（en）
------------------------------------------------- */
.eatMv{
  width: 100%;
  height: 200px;
  background-image: url(../images/pages/lang/en/eat/mv_warabi-sp@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 62px;

  @include media(sm) {
    height: 220px;
    background-image: url(../images/pages/lang/en/eat/mv_warabi-tb@2x.png);
  }

  @include media(md) {
    height: res(315px);
    background-image: url(../images/pages/lang/en/eat/mv_warabi-pc@2x.png);
    margin-top: res(74px);
  }

  @include media(lg) {
    height: 315px;
    margin-top: 74px;
  }

  & img{
    width: 100%;
  }
}

.eatMv__title{
  padding-top: 32px;
  font-family: $font;
  font-size: 2.7rem;
  font-weight: 500;
  line-height: line(36,27);
  letter-spacing: track(50);
  color: $color-wh;
  text-align: center;
  text-shadow: 0 0 11px rgba($color: #1F1F1F, $alpha: 0.8);

  @include media(sm) {
    padding-top: 60px;
    font-size: 3.7rem;
  }

  @include media(md) {
    padding-top: res(90px);
    font-size: res(5.6rem);
  }

  @include media(lg) {
    padding-top: 90px;
    font-size: 5.6rem;
  }
}

.eatMv__text{
  margin-top: 11px;
  font-family: $font;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: line(25,17);
  color: $color-wh;
  text-align: center;
  text-shadow: 0 0 11px rgba($color: #1F1F1F, $alpha: 0.8);

  @include media(sm) {
    margin-top: 20px;
    letter-spacing: track(50);
  }

  @include media(md) {
    margin-top: res(10px);
    font-size: res(2.4rem);
  }

  @include media(lg) {
    margin-top: 10px;
    font-size: 2.4rem;
  }
}

.eat{
  .secMain {
    padding: 0 10px;

    @include media(sm) {
      padding: 0 30px;
    }

    @include media(md) {
      padding: 0;
    }
  }
}

.eatBody{
  @include re-p;
  padding-top: 70px;
  padding-bottom: 90px;

  @include media(md) {
    padding-top: res(110px);
    padding-bottom: res(110px);
  }

  @include media(lg) {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}

.eatBody__item{
  margin-top: 70px;

  @include media(md) {
    display: flex;
    margin-top: res(102px);
  }

  @include media(lg) {
    margin-top: 102px;
  }
}

.eatBody__item:nth-child(2n){
  flex-direction: row-reverse;
}

.eatBody__item:first-child{
  margin-top: 0;
}

.eatBody__img{
  margin: 0 25px;

  @include media(md) {
    width: res(560px);
    min-width: res(560px);
    margin-left: 0;
    margin-right: res(80px);
  }

  @include media(lg) {
    width: 560px;
    min-width: 560px;
    margin-right: 80px;
  }
}

.eatBody__item:nth-child(2n) .eatBody__img{
  @include media(md) {
    margin-right: 0;
    margin-left: res(80px);
  }

  @include media(lg) {
    margin-left: 80px;
  }
}

.eatBody__img img{
  width: 100%;
  border-radius: 10px;

  @include media(md) {
    border-radius: 0;
  }
}

.eatContainer{
  padding-top: 28px;

  @include media(md) {
    width: 100%;
    margin-top: res(-2px);
    padding-top: 0;
  }

  @include media(lg) {
    margin-top: -2px;
  }
}

.eatContainer__title{
  position: relative;
  padding-left: 17px;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: track(50);
  line-height: line(25,20);
  color: $color-main;

  @include media(md) {
    padding-left: res(28px);
    font-size: res(2.8rem);
    line-height: line(30,28);
  }

  @include media(lg) {
    padding-left: 28px;
    font-size: 2.8rem;
  }
}

.eatContainer__title::after{
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 6px;
  height: 43px;
  background-color: $color-main;

  @include media(md) {
    width: res(6px);
    height: res(56px);
  }

  @include media(lg) {
    width: 6px;
    height: 56px;
  }
}

.eatContainer__time{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  width: 97px;
  height: 21px;
  background-color: #F2EFE1;
  border-radius: 50em;

  @include media(md) {
    margin-top: res(25px);
    width: res(108px);
    height: res(24px);
  }

  @include media(lg) {
    margin-top: 25px;
    width: 108px;
    height: 24px;
  }
}

.eatContainer__time > p{
  position: relative;
  padding-left: 4px;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: track(50);
  color: #84827F;

  @include media(md) {
    padding-left: res(7px);
    font-size: res(1.6rem);
  }

  @include media(lg) {
    padding-left: 7px;
    font-size: 1.6rem;
  }
}

.eatContainer__time > p::after{
  position: absolute;
  content: "";
  left: -14px;
  width: 15px;
  height: 15px;
  background-image: url(../images/common/icon/time.svg);
  background-size: contain;
  background-repeat: no-repeat;

  @include media(md) {
    top: -1px;
    left: res(-13px);
    width: res(17px);
    height: res(17px);
  }

  @include media(lg) {
    left: -13px;
    width: 17px;
    height: 17px;
  }
}

.eatContainer__head{
  position: relative;
  margin-top: 24px;
  margin-bottom: 8px;
  padding-left: 15px;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: track(50);

  @include media(md) {
    margin-top: res(30px);
    margin-bottom: res(8px);
    padding-left: res(15px);
    font-size: res(1.8rem);
  }

  @include media(lg) {
    margin-top: 30px;
    margin-bottom: 8px;
    padding-left: 15px;
    font-size: 1.8rem;
  }
}

.eatContainer__head .-second{
  margin-top: 24px;

  @include media(md) {
    margin-top: res(22px);
  }

  @include media(lg) {
    margin-top: 22px;
  }
}

.eatContainer__head::after{
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: $color-main;
  border-radius: 50em;

  @include media(md) {
    width: res(10px);
    height: res(10px);
  }

  @include media(lg) {
    width: 10px;
    height: 10px;
  }

}

.eatContainer__head > .-light{
  padding-left: 8px;
  font-size: 1.6rem;
  font-weight: 400;

  @include media(md) {
    padding-left: res(8px);
    font-size: res(1.6rem);
  }

  @include media(lg) {
    padding-left: 8px;
    font-size: 1.6rem;
  }
}

.eatContainer__item{
  margin-top: 2px;

  @include media(md) {
    margin-top: res(3px);
  }

  @include media(lg) {
    margin-top: 3px;
  }
}

.eatContainer__item:first-child{
  margin-top: 0;
}

.eatContainer__text{
  font-size: 1.6rem;
  letter-spacing: track(50);
  line-height: line(30,16);

  @include media(md) {
    font-size: res(1.6rem);
  }

  @include media(lg) {
    font-size: 1.6rem;
  }
}

.eatContainer__text .-small{
  @include media(md) {
    font-size: res(1.4rem);
  }

  @include media(lg) {
    font-size: 1.4rem;
  }
}

.eatContainer__item > .eatContainer__text{
  position: relative;
  padding-left: 20px;

  @include media(md) {
    padding-left: res(20px);
  }

  @include media(lg) {
    padding-left: 20px;
  }
}

.eatContainer__item > .eatContainer__text::after{
  position: absolute;
  content: "";
  top: 7px;
  left: 0;
  width: 17px;
  height: 17px;
  background-image: url(../images/common/icon/num-1.svg);
  background-size: contain;
  background-repeat: no-repeat;

  @include media(md) {
    top: res(7px);
    width: res(17px);
    height: res(17px);
  }

  @include media(lg) {
    top: 7px;
    width: 17px;
    height: 17px;
  }
}

.eatContainer__item:nth-child(2) > .eatContainer__text::after{
  background-image: url(../images/common/icon/num-2.svg);
}

.eatContainer__item:nth-child(3) > .eatContainer__text::after{
  background-image: url(../images/common/icon/num-3.svg);
}

.eatContainer__note{
  margin-top: 23px;
  padding: 15px 13px 12px;
  background-color: $color-bg;
  border-radius: 16px;

  @include media(md) {
    margin-top: res(26px);
    padding: res(15px) res(14px) res(12px);
  }

  @include media(lg) {
    margin-top: 26px;
    padding: 15px 14px 12px;
  }
}

.eatContainer__pen{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 81px;
  height: 24px;
  margin-bottom: 6px;
  background-color: $color-main;
  border-radius: 50em;

  @include media(md) {
    width: res(81px);
    height: res(24px);
    margin-bottom: res(6px);
  }

  @include media(lg) {
    width: 81px;
    height: 24px;
    margin-bottom: 6px;
  }
}

.eatContainer__pen > p{
  position: relative;
  padding-left: 13px;
  color: $color-wh;
  font-size: 1.5rem;
  letter-spacing: track(50);
  font-weight: 700;

  @include media(md) {
    padding-left: res(13px);
    font-size: res(1.5rem);
  }

  @include media(lg) {
    padding-left: 13px;
    font-size: 1.5rem;
  }
}

.eatContainer__note > .eatContainer__text{
  padding-left: 3px;

  @include media(md) {
    padding-left: res(3px);
  }

  @include media(lg) {
    padding-left: 3px;
  }
}

.eatContainer__pen > p::after{
  position: absolute;
  content: "";
  top: -1px;
  left: -6px;
  width: 16px;
  height: 16px;
  background-image: url(../images/common/icon/pen-wh.svg);
  background-size: contain;
  background-repeat: no-repeat;

  @include media(md) {
    left: res(-6px);
    width: res(16px);
    height: res(16px);
  }

  @include media(lg) {
    left: -6px;
    width: 16px;
    height: 16px;
  }
}
