/* メインの設定(全体)
------------------------------- */

// common
.anker {
  display: block;
  margin-top: -62px;
  padding-top: 62px;

  @include media(sm) {
    margin-top: -62px;
    padding-top: 62px;
  }

  @include media(md) {
    margin-top: res(-74px);
    padding-top: res(74x);
  }

  @include media(lg) {
    margin-top: -74px;
    padding-top: 74px;
  }
}

// inner
.innerW {
  position: relative;
  margin: 0 auto;
  padding: 0 10px;

  @include media(md) {
    max-width: res(1240px);
    padding: 0 res(20px);
  }

  @include media(lg) {
    max-width: 1240px;
    padding: 0 20px;
  }
}

.innerS {
  position: relative;
  margin: 0 auto;

  @include media(md) {
    max-width: res(980px);
  }

  @include media(lg) {
    max-width: 980px;
  }
}

// main
.main {
  display: block;
  overflow-x: hidden;

  &.-lower {
    margin-top: 62px;

    @include media(md) {
      margin-top: res(74px);
    }

    @include media(lg) {
      margin-top: 74px;
    }
  }
}

// asp
.-asp {
  position: relative;
  width: 100%;

  &::before {
    content: "";
    display: block;
  }

  img,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// br関連
.brSp {
  display: block;

  @include media(sm) {
    display: none;
  }
}

.brSp-TB {
  display: block;

  @include media(md) {
    display: none;
  }
}

.brPc {
  display: none;

  @include media(md) {
    display: block;
  }
}
