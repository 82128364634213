/* commitment
------------------------------------------------ */
.commitment {
  @include re-p;
  // 背景設定（/component/_bg.scss）
  padding-top: 84px;

  @include media(md) {
    padding-top: res(90px);
  }

  @include media(lg) {
    padding-top: 90px;
  }

  &__title {
    position: relative;
    padding: 13px 0 15px;
    border-top: 1px solid $color-bk;
    border-bottom: 1px solid $color-bk;
    text-align: center;

    @include media(md) {
      padding: res(14px) 0 res(16px);
    }

    @include media(lg) {
      padding: 14px 0 16px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color-bk;
    }

    &::before {
      top: 2px;
    }

    &::after {
      bottom: 2px;
    }

    .txt {
      position: relative;
      display: inline-block;
      padding: 0 44px 0 48px;
      font-size: 2.2rem;
      font-weight: 600;

      @include media(md) {
        padding: 0 res(50px) 0 res(55px);
        font-size: res(3.4rem);
      }

      @include media(lg) {
        padding: 0 50px 0 55px;
        font-size: 3.4rem;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 54%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background-image: url(../images/pages/products/commitment_icon_01.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include media(md) {
          width: res(32px);
          height: res(32px);
        }

        @include media(lg) {
          width: 32px;
          height: 32px;
        }
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }
  }

  .commitmentBlock {
    padding: 40px 0 60px;

    @include media(md) {
      padding: res(75px) 0 res(70px);
    }

    @include media(lg) {
      padding: 75px 0 70px;
    }

    &:first-of-type {
      padding-top: 30px;

      @include media(md) {
        padding-top: res(50px);
      }

      @include media(lg) {
        padding-top: 50px;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media(md) {
        flex-direction: row;
        align-items: flex-start;
      }
    }

    &__title {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(../images/pages/products/commitment_title_01.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      max-width: 100px;
      font-size: 1.7rem;
      font-weight: 600;
      line-height: line(14, 17);
      color: $color-wh;
      text-align: center;

      @include media(md) {
        max-width: res(170px);
        margin-right: res(70px);
        font-size: res(2.8rem);
      }

      @include media(lg) {
        max-width: 170px;
        margin-right: 70px;
        font-size: 2.8rem;
      }

      &::before {
        padding-top: percentage(115.01 / 100);
      }

      &.-gold {
        background-image: url(../images/pages/products/commitment_title_02.svg);
      }

      &.-row02 {
        padding-bottom: 4px;

        @include media(md) {
          padding-bottom: res(10px);
        }

        @include media(lg) {
          padding-bottom: 10px;
        }
      }
    }

    &__main {
      margin-top: 30px;

      @include media(md) {
        margin-top: 0;
      }
    }

    &__map {
      margin-bottom: 18px;

      @include media(sm) {
        max-width: res(500px);
        margin: 0 auto 18px;
      }

      @include media(md) {
        margin-bottom: res(8px);
      }

      @include media(lg) {
        max-width: 500px;
        margin-bottom: 8px;
      }
    }

    &__item {
      margin-top: 40px;

      @include media(md) {
        margin-top: res(56px);
      }

      @include media(lg) {
        margin-top: 56px;
      }

      &:first-child {
        margin-top: 0;
      }

      .info {
        &__title {
          position: relative;
          padding-left: 26px;
          font-size: 2rem;
          font-weight: 600;

          @include media(md) {
            padding-left: res(36px);
            font-size: res(2.8rem);
          }

          @include media(lg) {
            padding-left: 36px;
            font-size: 2.8rem;
          }

          &::before {
            content: "";
            position: absolute;
            top: 0.1em;
            left: 0;
            width: 18px;
            height: 18px;
            background-image: url(../images/pages/products/commitment_icon_02.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;

            @include media(md) {
              top: 0.2em;
              width: res(20px);
              height: res(20px);
            }

            @include media(lg) {
              width: 20px;
              height: 20px;
            }
          }

          &.-gold {
            &::before {
              background-image: url(../images/pages/products/commitment_icon_03.svg);
            }
          }
        }

        &__list,
        &__txt {
          margin-top: 10px;

          @include media(md) {
            margin-top: res(20px);
          }

          @include media(lg) {
            margin-top: 20px;
          }
        }

        &__item,
        &__txt,
        &__link {
          font-size: 1.6rem;
          line-height: line(30, 16);

          @include media(md) {
            font-size: res(1.6rem);
          }

          @include media(lg) {
            font-size: 1.6rem;
          }
        }

        &__item {
          padding-left: 1em;
          text-indent: -1em;
        }

        &__link {
          margin-top: 10px;
          color: #1284cb;
          text-decoration: underline;

          @include media(md) {
            margin-top: res(14px);
          }

          @include media(lg) {
            margin-top: 14px;
          }
        }

        &__img {
          @include re-m;
          margin-top: 15px;

          @include media(md) {
            margin: res(20px) 0 0;
          }

          @include media(lg) {
            margin-top: 20px;
          }

          &.-half {
            margin-top: 20px;

            @include media(sm) {
              max-width: 50%;
              margin: res(26px) 0 0;
            }

            @include media(lg) {
              margin-top: 26px;
            }
          }
        }

        .infoHaccp {
          margin-top: 30px;

          @include media(sm) {
            display: inline-block;
          }

          @include media(md) {
            margin-top: res(26px);
          }

          @include media(lg) {
            margin-top: 26px;
          }

          &__box {
            position: relative;
            margin-top: 30px;

            @include media(md) {
              margin-top: res(34px);
            }

            @include media(lg) {
              margin-top: 34px;
            }

            &:first-child {
              margin-top: 0;
            }
          }

          &__title {
            font-size: 1.8rem;
            font-weight: 600;

            @include media(md) {
              font-size: res(1.8rem);
            }

            @include media(lg) {
              font-size: 1.8rem;
            }
          }

          &__txt {
            margin-top: 12px;
            font-size: 1.6rem;
            line-height: line(30, 16);

            @include media(md) {
              margin-top: res(18px);
              font-size: res(1.6rem);
            }

            @include media(lg) {
              margin-top: 18px;
              font-size: 1.6rem;
            }
          }

          &__btn {
            max-width: 160px;
            margin-top: 10px;
            border: 2px solid $color-main;
            border-radius: 0.6em;

            @include media(md) {
              max-width: res(160px);
              margin-top: res(10px);
            }

            @include media(lg) {
              max-width: 160px;
              margin-top: 10px;
            }

            & > a {
              position: relative;
              padding: 8px 14px 10px;
              font-size: 1.6rem;
              color: $color-main;

              @include media(md) {
                padding: res(8px) res(14px) res(10px);
                font-size: res(1.6rem);
              }

              @include media(lg) {
                padding: 8px 14px 10px;
                font-size: 1.6rem;
              }

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                right: 12px;
                transform: translateY(-50%);
                width: 20px;
                height: 21px;
                background-image: url(../images/common/icon/pdf_pu_01.svg);
                background-repeat: no-repeat;
                background-size: contain;

                @include media(md) {
                  width: res(20px);
                  height: res(21px);
                }

                @include media(lg) {
                  width: 20px;
                  height: 21px;
                }
              }
            }
          }

          &__img {
            width: 234px;
            margin-top: 16px;

            @include media(sm) {
              position: absolute;
              top: 0;
              right: -18px;
              transform: translateX(100%);
              width: res(300px);
              margin-top: 0;
            }

            @include media(md) {
              right: res(-36px);
            }

            @include media(lg) {
              right: -36px;
              width: 300px;
            }
          }
        }
      }
    }
  }
}
