.languageSwitch {
  display: flex;
  justify-content: center;
  margin-left: -14px;

  @include media(md) {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
  }

  &__btn {
    width: calc(50% - 14px);
    margin-left: 14px;

    @include media(md) {
      width: 100%;
      margin-top: res(4px);
      margin-left: 0;

      &:first-child {
        margin-top: 0;
      }
    }

    @include media(lg) {
      margin-top: 4px;
    }

    & > a {
      background-color: $color-bk;
      padding: 10px 0 14px;
      border-radius: 2em;
      font-size: 1.6rem;
      font-weight: 600;
      color: $color-wh;
      text-align: center;

      @include media(md) {
        padding: res(4px) 0 res(7px);
        font-size: res(1.5rem);
      }

      @include media(lg) {
        padding: 4px 0 7px;
        font-size: 1.5rem;
      }
    }
  }

  // footer用
  &.-footer {
    @include media(md) {
      width: res(250px);
      margin-left: auto;
    }

    @include media(lg) {
      width: 250px;
    }

    .languageSwitch__btn {
      @include media(md) {
        margin-top: res(12px);

        &:first-child {
          margin-top: 0;
        }
      }

      @include media(lg) {
        margin-top: 12px;
      }

      & > a {
        @include media(md) {
          padding: res(8px) 0 res(12px);
          font-size: res(1.8rem);
        }

        @include media(lg) {
          padding: 8px 0 12px;
          font-size: 1.8rem;
        }
      }
    }
  }
}
