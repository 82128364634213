/* table関連
------------------------------------------------ */
.table {
  tr {
    border-top: 1px solid #c6c6c6;

    &:last-child {
      border-bottom: 1px solid #c6c6c6;
    }

    th,
    td {
      padding: 12px 0 14px;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: line(23, 15);
      text-align: left;
      vertical-align: top;

      @include media(md) {
        padding: res(20px) 0 res(22px);
        font-size: res(1.6rem);
      }

      @include media(lg) {
        padding: 20px 0 22px;
        font-size: 1.6rem;
      }
    }

    th {
      width: 100px;
      color: #84827f;

      @include media(sm) {
        width: 160px;
      }

      @include media(md) {
        width: res(240px);
        padding-left: res(15px);
      }

      @include media(lg) {
        width: 240px;
        padding-left: 15px;
      }
    }

    td {
      @include media(md) {
        padding-right: res(15px);
      }

      @include media(lg) {
        padding-right: 15px;
      }
    }
  }
}
