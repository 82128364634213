/* inquery
------------------------------------------------ */
.inquery {
  @include re-p;
  padding-top: 46px;
  border-top: 1px solid $color-bk;

  @include media(md) {
    padding-top: 100px;
  }

  @include media(lg) {
    padding-top: 100px;
  }

  &__title {
    font-size: 2rem;
    font-weight: 600;
    line-height: line(30, 20);
    text-align: center;

    @include media(md) {
      font-size: 3.4rem;
    }

    @include media(lg) {
      font-size: 3.4rem;
    }
  }

  &__btn {
    max-width: 400px;
    margin: 20px auto 0;

    @include media(sm) {
      max-width: 500px;
    }

    @include media(md) {
      max-width: res(640px);
      margin-top: res(36px);
    }

    @include media(lg) {
      max-width: 640px;
      margin-top: 36px;
    }

    & > a {
      position: relative;
      background-color: $color-main;
      padding: 20px 0 24px;
      border-radius: 0.6em;
      font-size: 1.8rem;
      font-weight: 600;
      letter-spacing: track(70);
      color: $color-wh;
      text-align: center;

      @include media(sm) {
        padding: res(29px) 0 res(33px);
        font-size: res(2.4rem);
      }

      @include media(lg) {
        padding: 29px 0 33px;
        font-size: 2.4rem;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 18px;
        width: 12px;
        height: 12px;
        border-top: 2px solid $color-wh;
        border-right: 2px solid $color-wh;
        transform: translateY(-50%) rotate(45deg);

        @include media(sm) {
          right: res(25px);
          width: res(14px);
          height: res(14px);
          border-top: 3px solid $color-wh;
          border-right: 3px solid $color-wh;
        }

        @include media(lg) {
          right: 25px;
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  &__txt {
    margin-top: 16px;
    font-size: 1.5rem;
    line-height: line(26, 15);
    text-align: center;

    @include media(md) {
      margin-top: res(16px);
      font-size: res(1.8rem);
    }

    @include media(lg) {
      margin-top: 16px;
      font-size: 1.8rem;
    }
  }

  &__list {
    max-width: 400px;
    margin: 40px auto 0;

    @include media(sm) {
      max-width: 500px;
    }

    @include media(md) {
      display: flex;
      max-width: none;
      margin: res(70px) 0 0 res(-70px);
    }

    @include media(lg) {
      margin-top: 70px;
      margin-left: -70px;
    }
  }

  &__item {
    overflow: hidden;
    margin-top: 15px;
    border-radius: 0.8em;

    @include media(md) {
      width: calc(50% - #{res(70px)});
      margin-top: 0;
      margin-left: res(70px);
    }

    @include media(lg) {
      width: calc(50% - 70px);
      margin-left: 70px;
    }

    & > a {
      background-image: url(../images/pages/products/inquery_bg_01.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      padding: 30px 0 32px;
      font-size: 1.8rem;
      font-weight: 600;
      color: $color-wh;
      text-align: center;

      @include media(md) {
        padding: res(60px) 0 res(62px);
        font-size: res(2.4rem);
      }

      @include media(lg) {
        padding: 60px 0 62px;
        font-size: 2.4rem;
      }

      .txt {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 54%;
          right: -42px;
          transform: translateY(-50%);
          background-image: url(../images/common/icon/arrow_wh_02.svg);
          background-repeat: no-repeat;
          background-size: contain;
          width: 30px;
          height: 30px;

          @include media(md) {
            right: res(-66px);
            width: res(44px);
            height: res(44px);
          }

          @include media(lg) {
            right: -66px;
            width: 44px;
            height: 44px;
          }
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }

    &:nth-child(2) {
      & > a {
        background-image: url(../images/pages/products/inquery_bg_02.png);
      }
    }
  }
}
