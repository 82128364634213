/* philosophy
------------------------------------------------ */
.philosophy {
  @include re-p;
  position: relative;
  background-color: #f2efe1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: $color-wh;
    z-index: 1;

    @include media(md) {
      display: none;
    }
  }

  &__inner {
    padding-bottom: 74px;

    @include media(md) {
      padding: res(94px) 0 res(74px);
    }

    @include media(lg) {
      padding: 94px 0 74px;
    }
  }

  &__container {
    @include media(md) {
      display: flex;
      align-items: center;
    }
  }

  &__img {
    position: relative;
    max-width: 440px;
    margin: 0 auto;
    z-index: 2;

    @include media(sm) {
      max-width: 500px;
    }

    @include media(md) {
      max-width: res(560px);
      width: 100%;
      margin-right: res(80px);
    }

    @include media(lg) {
      max-width: 560px;
      margin-right: 80px;
    }

    & > img {
      border-radius: 1em;
    }
  }

  &__info {
    position: relative;
    margin-top: 30px;

    @include media(md) {
      flex: 1;
      margin-top: 0;
    }
  }

  &__title {
    font-size: 1.6rem;
    font-weight: 600;

    @include media(md) {
      font-size: res(2rem);
    }

    @include media(lg) {
      font-size: 2rem;
    }
  }

  &__read {
    position: relative;
    display: inline-block;
    margin-top: 20px;
    padding: 0 30px 0 38px;
    font-size: 2.2rem;
    font-weight: 600;
    line-height: line(37, 22);

    @include media(md) {
      margin-top: res(24px);
      padding: 0 res(44px) 0 res(50px);
      font-size: res(2.8rem);
    }

    @include media(lg) {
      margin-top: 24px;
      padding: 0 44px 0 50px;
      font-size: 2.8rem;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 19.35px;
      background-image: url(../images/pages/company/philosophy_icon_01.svg);
      background-repeat: no-repeat;
      background-size: contain;

      @include media(md) {
        width: res(34.29px);
        height: res(27.66px);
      }

      @include media(lg) {
        width: 34.29px;
        height: 27.66px;
      }
    }

    &::before {
      top: 10%;
      left: 0;
    }

    &::after {
      right: 0;
      bottom: 6%;
      transform: rotate(180deg);
    }
  }

  &__txt {
    margin-top: 24px;
    font-size: 1.6rem;
    line-height: line(30, 16);

    @include media(md) {
      margin-top: res(34px);
      font-size: res(1.6rem);
    }

    @include media(lg) {
      margin-top: 34px;
      font-size: 1.6rem;
    }
  }

  .philosophyBox {
    margin-top: 20px;

    @include media(md) {
      margin-top: res(5px);
    }

    @include media(lg) {
      margin-top: 5px;
    }

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;

      @include media(md) {
        justify-content: flex-end;
      }
    }

    &__info {
      margin-right: 20px;

      @include media(md) {
        margin-right: res(20px);
      }

      @include media(lg) {
        margin-right: 20px;
      }
    }

    &__post {
      font-size: 1.3rem;

      @include media(md) {
        font-size: res(1.3rem);
      }

      @include media(lg) {
        font-size: 1.3rem;
      }
    }

    &__name {
      margin-top: 10px;
      font-size: 2rem;

      @include media(md) {
        margin-top: res(10px);
        font-size: res(2rem);
      }

      @include media(lg) {
        margin-top: 10px;
        font-size: 2rem;
      }
    }

    &__img {
      max-width: 104px;
      width: 100%;

      @include media(md) {
        max-width: res(104px);
      }

      @include media(lg) {
        max-width: 104px;
      }
    }
  }
}
