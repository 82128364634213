/* langTitle
------------------------------------------------ */
.langTitle {
  position: relative;
  margin: 0 calc(50% - 50vw);
  width: 100vw;
  height: 120px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include media(sm) {
    height: 220px;
  }

  @include media(md) {
    height: res(340px);
  }

  @include media(lg) {
    height: 340px;
  }

  &.-products {
    background-image: url(../images/pages/lang/langTitle_bg01_pc.png);

    @include media(md) {
      background-image: url(../images/pages/lang/langTitle_bg01_sp.png);
    }
  }

  &.-about {
    background-image: url(../images/pages/lang/langTitle_bg02_pc.png);

    @include media(md) {
      background-image: url(../images/pages/lang/langTitle_bg02_sp.png);
    }
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.1rem;
    font-weight: 700;
    white-space: nowrap;
    color: $color-wh;

    @include media(md) {
      font-size: res(4.2rem);
    }

    @include media(lg) {
      font-size: 4.2rem;
    }
  }
}
