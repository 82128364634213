/* TOP
------------------------------------------------- */

// topAbout
// ----------------------------------------------
.topAbout {
  // 背景設定（/component/_bg.scss）
  padding-bottom: 70px;

  @include media(sm) {
    padding-bottom: res(100px);
  }

  @include media(lg) {
    padding-bottom: 100px;
  }

  &__title {
    position: absolute;
    top: 70px;
    left: 20px;
    z-index: 1;
    padding-left: 18px;
    font-size: 2.2rem;
    font-weight: 600;
    letter-spacing: track(100);
    line-height: 1.6;
    text-align: left;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;

    @include media(sm) {
      top: res(70px);
      padding-left: res(18px);
    }

    @include media(md) {
      left: 0;
      font-size: res(3.6rem);
      letter-spacing: track(150);
    }

    @include media(lg) {
      top: 70px;
      padding-left: 18px;
      font-size: 3.6rem;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      display: block;
      width: 6px;
      height: 30px;

      @include media(md) {
        width: res(6px);
        height: res(30px);
      }

      @include media(lg) {
        width: 6px;
        height: 30px;
      }
    }

    &::before {
      top: 0;
      background-color: #c7a265;
    }

    &::after {
      top: 30px;
      background-color: $color-main;

      @include media(sm) {
        top: res(30px);
      }

      @include media(lg) {
        top: 30px;
      }
    }

    &.-re {
      top: 60px;
      right: 20px;
      padding-right: 18px;
      padding-left: 0;

      @include media(sm) {
        top: res(70px);
        padding-right: res(18px);
      }

      @include media(md) {
        right: 0;
      }

      @include media(lg) {
        top: 70px;
        padding-right: 18px;
      }

      &::before,
      &::after {
        right: 0;
      }
    }
  }

  &__txt {
    font-size: 1.5rem;
    line-height: line(32, 15);
    text-align: center;

    @include media(sm) {
      font-size: res(1.6rem);
      line-height: line(34, 16);
    }

    @include media(lg) {
      font-size: 1.6rem;
    }
  }

  .topAbout__illust {
    position: absolute;
    z-index: 1;

    &.-intro {
      top: 20%;
      right: 10px;
      max-width: 140px;

      @include media(sm) {
        top: auto;
        bottom: 33%;
      }

      @include media(md) {
        max-width: res(210px);
        right: 12.6%;
      }

      @include media(lg) {
        max-width: 210px;
      }
    }

    &.-commit {
      top: 22.4%;
      left: 10px;
      max-width: 130px;

      @include media(sm) {
        top: 48.4%;
      }

      @include media(md) {
        left: 2.5%;
        max-width: res(222px);
      }

      @include media(lg) {
        max-width: 222px;
      }
    }
  }

  .topAboutIntro {
    position: relative;
    padding-top: 370px;
    padding-bottom: 50px;

    @include media(sm) {
      padding-top: res(110px);
      padding-bottom: res(60px);
    }

    @include media(lg) {
      padding-top: 110px;
      padding-bottom: 60px;
    }

    &__read {
      margin-top: 36px;
      margin-bottom: 12px;
      font-size: 1.9rem;
      text-align: center;

      @include media(sm) {
        margin-top: res(52px);
        margin-bottom: res(16px);
        font-size: res(2.6rem);
      }

      @include media(lg) {
        margin-top: 52px;
        margin-bottom: 16px;
        font-size: 2.6rem;
      }
    }

    &__img {
      @include re-m;
      margin-top: 26px;
      max-width: 660px;

      @include media(sm) {
        margin: res(20px) auto 0;
      }

      @include media(md) {
        max-width: res(996px);
      }

      @include media(lg) {
        max-width: 996px;
        margin-top: 20px;
      }
    }
  }

  .topAboutCommit {
    position: relative;
    padding-top: 294px;

    @include media(sm) {
      padding-top: res(76px);
    }

    @include media(lg) {
      padding-top: 76px;
    }

    &__area {
      position: relative;
      padding-top: 206px;

      @include media(sm) {
        padding-top: 230px;
      }

      @include media(md) {
        padding-top: res(444px);
      }

      @include media(lg) {
        padding-top: 444px;
      }
    }

    &__img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 410px;
      margin: 0 auto;

      @include media(sm) {
        max-width: 460px;
        width: 100%;
      }

      @include media(md) {
        max-width: res(885px);
      }

      @include media(lg) {
        max-width: 885px;
      }
    }

    &__info {
      margin-top: 32px;

      @include media(sm) {
        margin-top: res(50px);
      }

      @include media(lg) {
        margin-top: 50px;
      }
    }

    &__btn {
      max-width: 400px;
      margin: 36px auto 0;
      padding: 0 10px;

      @include media(sm) {
        max-width: res(320px);
        margin-top: res(50px);
        padding: 0;
      }

      @include media(lg) {
        max-width: 320px;
        margin-top: 50px;
      }
    }
  }
}

// topInfo
// ----------------------------------------------
.topInfo {
  background: #b99667 url(../images/common/bg/bg_02.png);
  background-size: 80px;
  background-position: center;
  padding-top: 64px;
  padding-bottom: 70px;

  @include media(sm) {
    padding-top: res(100px);
    padding-bottom: res(100px);
  }

  @include media(lg) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &__container {
    @include media(sm) {
      display: flex;
    }
  }

  &__line {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    background-image: url(../images/common/bg/bg_03.png);
    background-position: center;
    background-size: 80px;
    width: 100%;
    height: 1220px;

    @include media(sm) {
      height: 1010px;
    }

    @include media(md) {
      height: res(393px);
    }

    @include media(lg) {
      height: 393px;
    }
  }

  &__title {
    margin: 0 auto;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;

    @include media(sm) {
      margin: 0;
      margin-right: 50px;
      padding-left: 30px;
    }

    @include media(md) {
      margin-right: res(70px);
    }

    @include media(lg) {
      margin-right: 70px;
    }

    & > .txt {
      position: relative;
      display: inline-block;
      font-size: 2.5rem;
      font-weight: 600;
      letter-spacing: track(120);
      line-height: 1.6;
      color: $color-wh;
      padding-bottom: 78px;

      @include media(sm) {
        font-size: 2.8rem;
        line-height: 1;
      }

      @include media(md) {
        padding-bottom: res(96px);
        font-size: res(4rem);
      }

      @include media(lg) {
        padding-bottom: 96px;
        font-size: 4rem;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 58%;
        transform: translateX(-50%);
        z-index: 1;
        display: block;
        width: 87px;
        height: 68px;
        background-image: url(../images/pages/top/topInfo_illust_01@2x.png);
        background-repeat: no-repeat;
        background-size: contain;

        @include media(sm) {
          left: 60%;
          width: res(87px);
          height: res(68px);
        }

        @include media(lg) {
          width: 87px;
          height: 68px;
        }
      }
    }
  }

  &__block {
    position: relative;
    background-color: $color-wh;
    margin-top: 46px;
    padding: 50px 10px 50px 24px;

    @include media(sm) {
      margin-top: 0;
      padding: res(60px) 0 res(60px) res(60px);
    }

    @include media(lg) {
      padding: 60px 0 60px 60px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(100%);
      width: 100%;
      height: 100%;
      background-color: $color-wh;
    }
  }

  .topInfoList {
    max-width: 460px;
    margin: 0 auto;

    @include media(sm) {
      max-width: 520px;
    }

    @include media(md) {
      display: flex;
      max-width: none;
      margin: 0;
      margin-left: res(-50px);
    }

    @include media(lg) {
      margin-left: -50px;
    }

    &__item {
      margin-top: 60px;

      @include media(sm) {
      }

      @include media(md) {
        width: calc(50% - #{res(50px)});
        margin-top: 0;
        margin-left: res(50px);
      }

      @include media(lg) {
        width: calc(50% - 50px);
        margin-left: 50px;
      }

      &:first-child {
        margin-top: 0;
      }

      .card {
        &__box {
          position: relative;
          border-radius: 1.2em;
          overflow: hidden;
        }

        &__title {
          position: absolute;
          top: 0;
          left: 0;
          background-color: $color-wh;
          padding: 0 12px 12px 4px;
          border-radius: 0 0 0.6em 0;
          font-size: 2rem;
          font-weight: 600;
          letter-spacing: track(70);
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;

          @include media(sm) {
          }

          @include media(md) {
            padding: 0 res(20px) res(16px) res(2px);
            border-radius: 0 0 0.4em 0;
            font-size: res(2.8rem);
          }

          @include media(lg) {
            padding: 0 20px 16px 2px;
            border-radius: 0 0 0.4em 0;
            font-size: 2.8rem;
          }
        }

        &__txt {
          margin-top: 20px;
          font-size: 1.5rem;
          line-height: line(29, 15);

          @include media(sm) {
            margin-top: res(20px);
            font-size: res(1.6rem);
          }

          @include media(lg) {
            margin-top: 20px;
            font-size: 1.6rem;
          }
        }

        &__btn {
          max-width: 200px;
          margin-top: 20px;

          @include media(sm) {
            max-width: res(222px);
            margin-top: res(26px);
          }

          @include media(lg) {
            max-width: 222px;
            margin-top: 26px;
          }
        }
      }
    }
  }
}

// topLink
// ----------------------------------------------
.topLink {
  &__inner {
    padding: 100px 10px;

    @include media(sm) {
      padding: res(102px) 0 res(135px);
    }

    @include media(md) {
    }

    @include media(lg) {
      padding: 102px 0 135px;
    }
  }

  &__list {
    max-width: 460px;
    margin: 0 auto;

    @include media(sm) {
      max-width: 480px;
    }

    @include media(md) {
      display: flex;
      max-width: none;
      margin: 0;
      margin-left: res(-80px);
    }

    @include media(lg) {
      margin-left: -80px;
    }
  }

  &__item {
    margin-top: 60px;

    @include media(sm) {
      margin-top: 80px;
    }

    @include media(md) {
      width: calc(50% - #{res(80px)});
      margin-top: 0;
      margin-left: res(80px);
    }

    @include media(lg) {
      width: calc(50% - 80px);
      margin-left: 80px;
    }

    &:first-child {
      margin-top: 0;
    }

    .card {
      position: relative;

      &__img {
        border-radius: 1.2em;
        overflow: hidden;
      }

      &__btn {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        max-width: 290px;
        width: 100%;

        @include media(sm) {
          max-width: 400px;
        }

        @include media(md) {
          max-width: res(462px);
        }

        @include media(lg) {
          max-width: 462px;
        }
      }
    }
  }
}

/* スマホ横
------------------------------------------------ */
@media only screen and (orientation: landscape) and (max-width: 813px) {
}
