/* overview
------------------------------------------------ */
.overview {
  padding-top: 70px;

  @include media(md) {
    padding-top: res(90px);
  }

  @include media(lg) {
    padding-top: 90px;
  }

  &__container {
    max-width: 440px;
    margin: 30px auto 0;

    @include media(sm) {
      display: flex;
      max-width: none;
      margin: 30px 0 0;
      margin-left: -20px;
    }

    @include media(md) {
      margin-top: res(50px);
      margin-left: res(-30px);
    }

    @include media(lg) {
      margin-top: 50px;
      margin-left: -30px;
    }
  }

  &__img {
    width: 100%;
    margin-top: 16px;

    @include media(sm) {
      width: calc(50% - #{res(30px)});
      margin-top: 0;
      margin-left: 20px;
    }

    @include media(md) {
      margin-left: res(30px);
    }

    @include media(lg) {
      width: calc(50% - 30px);
      margin-left: 30px;
    }

    & > img {
      border-radius: 1em;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__table {
    margin-top: 30px;

    @include media(md) {
      margin-top: res(50px);
    }

    @include media(lg) {
      margin-top: 50px;
    }
  }

  .overviewAttempt {
    margin-top: 40px;

    @include media(md) {
      margin-top: res(40px);
    }

    @include media(lg) {
      margin-top: 40px;
    }

    &__read {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: line(32, 18);

      @include media(md) {
        font-size: res(2.2rem);
      }

      @include media(lg) {
        font-size: 2.2rem;
      }
    }

    &__list {
      margin-top: 20px;

      @include media(md) {
        margin-top: res(20px);
      }

      @include media(lg) {
        margin-top: 20px;
      }
    }

    &__item {
      background-color: #f2efe1;
      margin-top: 15px;
      padding: 26px 20px 22px;
      border-radius: 1em;

      @include media(md) {
        margin-top: res(20px);
        padding: res(36px) res(40px) res(36px);
      }

      @include media(lg) {
        margin-top: 20px;
        padding: 36px 40px 36px;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    &__title {
      position: relative;
      padding-left: 20px;
      font-size: 1.8rem;
      font-weight: 600;

      @include media(md) {
        padding-left: res(20px);
        font-size: res(2rem);
      }

      @include media(lg) {
        padding-left: 20px;
        font-size: 2rem;
      }

      &::before {
        content: "";
        position: absolute;
        top: 55%;
        left: 0;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        background-color: $color-main;
        border-radius: 4em;

        @include media(md) {
          width: res(10px);
          height: res(10px);
        }

        @include media(lg) {
          width: 10px;
          height: 10px;
        }
      }
    }

    &__txt {
      margin-top: 14px;
      font-size: 1.6rem;
      line-height: line(30, 16);

      @include media(md) {
        margin-top: res(16px);
        font-size: res(1.6rem);
      }

      @include media(lg) {
        margin-top: 16px;
        font-size: 1.6rem;
      }

      & > span {
        display: block;
        padding-left: 1em;
        text-indent: -1em;
        line-height: line(30, 16);
      }
    }
  }

  &__history {
    margin-top: 70px;

    @include media(md) {
      margin-top: res(90px);
    }

    @include media(lg) {
      margin-top: 90px;
    }
  }
}
