.attempt {
  .secMain {
    padding-bottom: 0;

    @include media(sm) {
      padding-bottom: 0;
    }

    @include media(md) {
      padding-bottom: 0;
    }

    @include media(lg) {
      padding-bottom: 0;
    }
  }

  .sdgs {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media(sm) {
      flex-direction: row;
      justify-content: center;
    }

    &__logo {
      width: 176px;
      flex-shrink: 0;

      @include media(md) {
        width: res(176px);
      }

      @include media(lg) {
        width: 176px;
      }
    }

    &__txt {
      margin-top: 20px;
      font-size: 1.6rem;
      line-height: line(30, 16);

      @include media(sm) {
        margin-top: -6px;
        margin-left: res(32px);
      }

      @include media(md) {
        font-size: res(1.8rem);
      }

      @include media(lg) {
        font-size: 1.8rem;
        margin-left: 32px;
        margin-top: -4px;
      }
    }
  }

  .sdgsList {
    margin: 0 calc(50% - 50vw);
    margin-top: 50px;
    padding: 60px 10px 90px;
    background-color: #f2efe1;

    @include media(md) {
      padding: 0 calc(50vw - 50%);
      margin-top: res(73px);
      padding-top: res(80px);
      padding-bottom: res(110px);
    }

    @include media(lg) {
      margin-top: 73px;
      padding-top: 80px;
      padding-bottom: 110px;
    }

    &__item {
      margin-top: 60px;

      @include media(md) {
        display: flex;
        margin-top: res(70px);
      }

      @include media(lg) {
        margin-top: 70px;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    &__container {
      padding: 53px 10px 0;
      background-image: url(../images/common/bg/bg_04_wh.png);
      background-repeat: repeat-x;
      background-size: auto 25px;

      @include media(md) {
        padding: 0;
        padding-top: res(60px);
        background-size: auto res(23px);
      }

      @include media(lg) {
        padding-top: 60px;
        background-size: auto 23px;
      }
    }

    &__title {
      position: relative;
      padding-left: 26px;
      font-size: 2rem;
      line-height: line(28, 20);

      @include media(md) {
        padding-left: res(33px);
        font-size: res(2.8rem);
      }

      @include media(lg) {
        padding-left: 33px;
        font-size: 2.8rem;
      }

      &::before {
        content: "";
        position: absolute;
        top: 5px;
        left: 0;
        width: 18px;
        height: 18px;
        background-image: url(../images/pages/attempt/attempt_icon.svg);
        background-repeat: no-repeat;
        background-size: contain;

        @include media(sm) {
          top: 50%;
          transform: translateY(-50%);
        }

        @include media(md) {
          width: res(20px);
          height: res(20px);
        }

        @include media(lg) {
          width: 20px;
          height: 20px;
        }
      }
    }

    &__txt {
      margin-top: 20px;
      font-size: 1.6rem;
      line-height: line(30, 16);

      @include media(md) {
        margin-top: res(25px);
        font-size: res(1.6rem);
      }

      @include media(lg) {
        margin-top: 25px;
        font-size: 1.6rem;
      }

      // & .narrow {
      //   letter-spacing: track(-3);

      //   @include media(sm) {
      //     letter-spacing: track(50);
      //   }
      // }
    }

    &__img {
      margin-top: 20px;
      padding: 0 10px;

      @include media(sm) {
      }

      @include media(md) {
        margin-top: 0;
        padding: 0;
        margin-left: res(80px);
        width: res(560px);
        flex-shrink: 0;
      }

      @include media(lg) {
        margin-left: 80px;
        width: 560px;
      }

      & > img {
        width: 100%;
        height: auto;
        border-radius: 1em;

        @include media(md) {
          border-radius: 1.4em;
        }
      }
    }

    .mark {
      margin-top: 15px;
      display: flex;

      @include media(sm) {
      }

      @include media(md) {
        margin-top: res(20px);
      }

      @include media(lg) {
        margin-top: 20px;
      }

      &__item {
        margin-left: 5px;
        width: 60px;
        height: 60px;

        @include media(sm) {
          margin-left: res(12px);
          width: res(77px);
          height: res(77px);
        }

        @include media(md) {
        }

        @include media(lg) {
          margin-left: 12px;
          width: 77px;
          height: 77px;
        }

        &:first-child {
          margin-left: 0px;
        }
      }

      &__img {
        & > img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
