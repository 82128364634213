/* dot関連
------------------------------------------------ */
.dotted {
  width: 100%;
  height: 4px;
  background-image: url(../images/common/icon/dot_bk_01.png);
  background-repeat: repeat-x;
  background-position: center;
  background-size: 10px;

  @include media(sm) {
    height: res(4px);
    background-size: res(10px);
  }

  @include media(lg) {
    height: 4px;
    background-size: 10px;
  }
}
