/* pageTop
------------------------------------------------ */
.pageTop {
  position: fixed;
  right: 20px;
  z-index: 5;
  display: none;

  @include media(md) {
    right: 4%;
  }

  & > a {
    max-width: 50px;
    width: 100%;

    @include media(sm) {
      max-width: res(60px);
    }

    @include media(lg) {
      max-width: 60px;
    }

    &::before {
      padding-top: 100%;
    }
  }
}
