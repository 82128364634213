/* lineup
------------------------------------------------ */
.lineup {
  padding: 72px 0 68px;

  @include media(md) {
    padding: res(86px) 0 res(92px);
  }

  @include media(lg) {
    padding: 86px 0 92px;
  }

  &__list {
    margin-top: 30px;

    @include media(md) {
      margin-top: res(52px);
    }

    @include media(lg) {
      margin-top: 52px;
    }
  }

  &__item {
    margin-top: 60px;
    @include media(md) {
      margin-top: 72px;
    }

    @include media(lg) {
      margin-top: res(72px);
    }

    &:first-child {
      margin-top: 0;
    }

    .card {
      @include media(md) {
        display: flex;
      }

      &__img {
        @include media(md) {
          max-width: res(560px);
          width: 100%;
          margin-right: res(80px);
          border-radius: 2em;
        }

        @include media(lg) {
          max-width: 560px;
          margin-right: 80px;
        }

        & > img {
          border-radius: 1em;

          @include media(md) {
            border-radius: 1.4em;
          }
        }
      }

      &__vegan{
        display: flex;

        & > img{
          width: 100%;
          max-width: 62px;
          height: 50px;
          margin-top: 15px;
          margin-right: 13px;

          @include media(md){
            max-width: res(88px);
            height: res(70px);
            margin-top: res(23px);
            margin-right: res(15px);
          }

          @include media(lg){
            max-width: 88px;
            height: 70px;
            margin-top: 23px;
            margin-right: 15px;
          }
        }

        &-txt {
          margin-top: 16px;
          font-size: 14px;
          line-height: line(24,14);
          width: 100%;
          letter-spacing: track(20);

          @include media(md) {
            margin-top: res(20px);
            font-size: res(16px);
            line-height: line(30,16);
            letter-spacing: track(50);
          }

          @include media(lg) {
            margin-top: 20px;
            font-size: 16px;
            line-height: 1.9em;
          }
        }
      }

      &__box {
        margin-top: 22px;

        @include media(md) {
          flex: 1;
          margin-top: 0;
        }
      }

      &__title {
        position: relative;
        padding-left: 18px;
        font-size: 2rem;
        font-weight: 600;
        color: $color-main;

        @include media(md) {
          padding-left: res(26px);
          font-size: res(2.6rem);
        }

        @include media(lg) {
          padding-left: 26px;
          font-size: 2.6rem;
        }

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          display: block;
          width: 6px;
          height: 22px;
          background-color: $color-main;

          @include media(md) {
            width: res(6px);
            height: res(25px);
          }

          @include media(lg) {
            width: 6px;
            height: 25px;
          }
        }
      }

      &__txt {
        margin-top: 20px;
        font-size: 1.6rem;
        line-height: line(30, 16);

        @include media(md) {
          margin-top: res(26px);
          font-size: res(1.6rem);
        }

        @include media(lg) {
          margin-top: 26px;
          font-size: 1.6rem;
        }

        .bold {
          font-weight: 600;
        }

        &.-mt {
          margin-top: 8px;

          @include media(md) {
            margin-top: res(12px);
          }

          @include media(lg) {
            margin-top: 12px;
          }
        }

        &--compact {
          @include media(md) {
            letter-spacing: 0.02em;
          }
        }
      }



      &__area {
        margin-top: 20px;
        padding: 14px 0 16px;
        border-top: 1px solid #c6c6c6;
        border-bottom: 1px solid #c6c6c6;

        @include media(md) {
          margin-top: res(28px);
          padding: res(16px) 0 res(18px);
        }

        @include media(lg) {
          margin-top: 28px;
          padding: 16px 0 18px;
        }
      }

      .cardList {
        display: flex;
        margin-top: 10px;
        font-size: 1.5rem;

        @include media(md) {
          margin-top: res(12px);
          font-size: res(1.5rem);
        }

        @include media(lg) {
          margin-top: 12px;
          font-size: 1.5rem;
        }

        &:first-child {
          margin-top: 0;
        }

        &__title,
        &__txt {
          line-height: line(25, 15);
        }

        &__title {
          position: relative;
          max-width: 92px;
          width: 100%;
          padding-left: 15px;
          color: #84827f;

          @include media(md) {
            max-width: res(92px);
            padding-left: res(15px);
          }

          @include media(lg) {
            max-width: 92px;
            padding-left: 15px;
          }

          &::before {
            content: "";
            position: absolute;
            top: 0.55em;
            left: 0;
            width: 10px;
            height: 10px;
            background-color: #84827f;
            border-radius: 8em;

            @include media(md) {
              width: res(10px);
              height: res(10px);
            }

            @include media(lg) {
              width: 10px;
              height: 10px;
            }
          }
        }
      }
    }
  }
}
