/* CSS Document */
* {
  margin: 0;
  padding: 0;
  line-height: 1;
}

*,
::after,
::before {
  box-sizing: inherit;
}

html {
  overflow-x: hidden;
  font-size: $font-size;
  font-feature-settings: "palt";
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  word-wrap: break-word;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: $font;
  font-weight: 500;
  letter-spacing: track(50);
  color: $color-bk;

  &.en {
    font-family: $fontEn_01;
  }

  &.cn {
    font-family: $fontCn_01_400;
  }
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
p,
dl,
dt,
dd,
img {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-weight: 500;
}

table {
  border-collapse: collapse;
  width: 100%;
}

a {
  display: block;
  color: inherit;
  text-decoration: none;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }
}

button,
input,
optgroup,
select,
textarea {
  font-size: 1.6rem;
}

button {
  background-color: transparent;
  border: none;
  font-family: inherit;
  outline: none;
  padding: 0;
  appearance: none;
  transition: 0.4s;
  cursor: pointer;
}

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;

  &:focus {
    outline-offset: -2px;
  }
}

address {
  font-style: normal;
}
