/* header
------------------------------------------------ */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $color-wh;
  z-index: 101;

  @include media(md) {
    top: auto;
    bottom: 0;
  }

  &.-lower {
    top: 0;
    bottom: auto;
  }

  &__inner {
    z-index: 50;
    display: flex;
    align-items: center;
    width: 100%;
    height: 62px;
    padding: 0 10px 0 20px;

    @include media(md) {
      height: res(74px);
      padding: 0 res(12px) 0 res(20px);
    }

    @include media(lg) {
      height: 74px;
      padding: 0 12px 0 20px;
    }
  }

  .headerLogo {
    & > a {
      font-size: 1.7rem;
      font-weight: 600;
      letter-spacing: track(90);

      @include media(md) {
        font-size: res(2.2rem);
        letter-spacing: track(140);
      }

      @include media(lg) {
        font-size: 2.2rem;
      }
    }
  }

  // gnav
  .gnav {
    display: none;

    @include media(md) {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    .gnavList {
      display: flex;
      align-items: center;
      height: 100%;

      &__item {
        margin-right: 24px;

        @include media(lg) {
          margin-right: 44px;
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          position: relative;
          font-size: res(1.5rem);
          transition: color 0.2s;

          @include media(lg) {
            font-size: 1.5rem;
          }

          &:hover {
            opacity: 1;

            @include media(sm) {
              color: $color-main;
            }
          }
        }
      }
    }

    &__language {
      width: res(100px);
      margin-left: res(22px);

      @include media(lg) {
        width: 100px;
        margin-left: 22px;
      }
    }
  }

  // .gnavSp
  .gnavSp {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin-left: auto;

    @include media(sm) {
      width: 48px;
      height: 48px;
    }

    @include media(md) {
      display: none;
    }
  }
}
