// drawer-btn
.drawer-btn {
  position: relative;
  width: 30px;
  height: 22px;
  z-index: 101;
  cursor: pointer;

  & > span {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: $color-bk;
    transform-origin: 50%;
    transition: 0.4s;

    &:nth-of-type(1) {
      transform: rotate(0) translateY(-11px);

      @include media(sm) {
        transform: rotate(0) translateY(-10px);
      }
    }

    &:nth-of-type(3) {
      transform: rotate(0) translateY(11px);

      @include media(sm) {
        transform: rotate(0) translateY(10px);
      }
    }
  }

  &.active {
    & > span {
      background-color: $color-wh;

      &:nth-of-type(1) {
        transform: translateY(0) rotate(-45deg);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: translateY(0) rotate(45deg);
      }
    }
  }
}

//　drawer-modal
.drawer-modal {
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-main, 0.97);
  transition: all 0.5s;
  visibility: hidden;
  opacity: 0;
  z-index: 99;

  &.open {
    visibility: visible;
    opacity: 1;
  }

  &__inner {
    width: 100%;
    height: 100%;
    padding: 22px 20px 0;
  }

  &__logo {
    font-size: 1.7rem;
    font-weight: 600;
    letter-spacing: track(90);
    color: $color-wh;
  }

  &__list {
    width: 100%;
    margin-top: 76px;

    @include media(sm) {
      max-width: 454px;
      margin: 220px auto 0;
    }
  }

  &__item {
    margin-top: 30px;

    @include media(sm) {
      margin-top: 52px;
    }

    &:first-child {
      margin-top: 0;
    }

    & > a {
      font-size: 1.8rem;
      color: $color-wh;

      @include media(sm) {
        font-size: 2.2rem;
      }
    }
  }

  &__language {
    width: 100%;
    margin-top: 46px;

    @include media(sm) {
      max-width: 454px;
      margin: 80px auto 0;
    }
  }
}

/* スマホ横
------------------------------------------------ */
@media only screen and (orientation: landscape) and (max-width: 813px) {
  .drawer-modal {
    position: fixed;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &__container {
      height: 300px !important;
      margin-bottom: 50px;
    }
  }
}
