/* 背景関連
------------------------------------------------ */
.topAbout,
.commitment,
.companyAbout,
.langAbout,
.eatBody {
  background-image: url(../images/common/bg/bg_01.png);
  background-size: 300px;
  background-position: center;
}
