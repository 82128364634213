/* #### Generated By: http://www.cufonfonts.com #### */

// @font-face {
//   font-family: 'フォント名';
//   font-style: normal;
//   font-weight: normal;
//   src:
//   local('フォント名前※日本語可'),//localを先に指定する
//   url('../fonts/○○.woff') format('woff'),
//   url('../fonts/○○.ttf') format('ttf');
//   font-display: swap;
// }
//

// NotoSansSC-Regular
@font-face {
  font-family: "NotoSansSC-Regular";
  font-style: normal;
  font-weight: 400;
  src: local("NotoSansSC-Regular"), url("../fonts/NotoSansSC-Regular.otf") format("opentype");
  font-display: swap;
}

// NotoSansSC-Bold
@font-face {
  font-family: "NotoSansSC-Bold";
  font-style: normal;
  font-weight: 700;
  src: local("NotoSansSC-Bold"), url("../fonts/NotoSansSC-Bold.otf") format("opentype");
  font-display: swap;
}

// NotoSerifSC-Regular
@font-face {
  font-family: "NotoSerifSC-Regular";
  font-style: normal;
  font-weight: 400;
  src: local("NotoSerifSC-Regular"), url("../fonts/NotoSerifSC-Regular.otf") format("opentype");
  font-display: swap;
}

// NotoSerifSC-Bold
@font-face {
  font-family: "NotoSerifSC-Bold";
  font-style: normal;
  font-weight: 700;
  src: local("NotoSerifSC-Bold"), url("../fonts/NotoSerifSC-Bold.otf") format("opentype");
  font-display: swap;
}
